import constants from "./constants";

const parseLines = (value) => {
    if (value) {
        return value.replace(/(\\n)/g, "\n")
    } else {
        return ""
    }
}

const formatKnowledge = (knowledge) => {
    const joinedStrings = Object.values(knowledge)
      .flat()
      .join('\n');
    return joinedStrings
}


const checkExtension = (userinfo, setInstalled) => {
        try {
            chrome.runtime.sendMessage(
                constants.CHROME_ID,
                { type: "check_exists" },
                function(response) {
                    console.log('Exists check', response)
                    if (response) {
                        setInstalled(true)
                        if (userInfo.info.uid) {
                            try {
                                chrome.runtime.sendMessage(
                                    constants.CHROME_ID,
                                    { user: userInfo.info.uid, type: "sign_in" },
                                    function(response) {
                                        console.log(response)
                                    }
                                );
                            } catch(error) {
                                console.error(error)
                            }
                        }
                    }
                }
            );
        } catch(error) {
            console.error(error)
            setInstalled(false)
        }
    }

export default {
    formatKnowledge,
    checkExtension,
    parseLines
}