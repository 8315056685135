import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button, Stack} from '@mui/material';
import { PreferencePairs } from '../components/PreferencePair';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import constants from '../constants';

function getWC(string) {
    const words = string.split(' ')
    return words.length
}

export function SurveyPage() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [survey, setSurvey] = useState({})
    const [errors, setError] = useState({})
    const [pairs, setPairs] = useState({})

    const surveyQuestions = {
        'without-knoll': 'What types of queries did you send when using Knoll that you otherwise would not have asked ChatGPT or Claude?',
        'like': 'What was the best part about using Knoll? What was the worst part?',
        'other-modules': 'What other types of external knowledge would you find useful for AI chatbots to have access to?'
    }

    const keys = Object.keys(surveyQuestions)
    function onUpdateSurvey(evt) {
        const keyName = evt.target.id
        const value = evt.target.value 
        let newSurvey = {...survey}
        newSurvey[keyName] = value
        setSurvey(newSurvey)
    }


    function changeRadio(evt) {
        const keyName = evt.target.name
        const value = evt.target.value 
        let newSurvey = {...survey}
        newSurvey[keyName] = value
        setSurvey(newSurvey)
    }

    function checkDisabled() {
        const checkKeys = ['without-knoll', 'like', 'other-modules', 'isInterview']
        let count = 0 
        checkKeys.forEach(key => {
            if (key in survey) {
                count += 1
            }
        })
        if (count === 4) {
            return false
        } else {
            return true
        }
    }

    function onSubmit() {
        let count = 0 
        let newErrors = {...errors}
        let canAdvance = false

        // check for completion and validate responses
        keys.forEach(key => {
            if (!(key in survey)) {
                newErrors[key] = true
            } else if (key in survey && getWC(survey[key]) >= 10) {
                count += 1
                newErrors[key] = false
            } else {
                newErrors[key] = true
            }
        })

        if (count === keys.length && 'isInterview' in survey) {
            canAdvance = true
        } else {
            canAdvance = false
            setError(newErrors)
        }

        if (canAdvance) {
            const data = {
                uid: id, 
                ...survey
            }

            axios.post(`${constants.SERVER_API}/submitSurvey`, data)
            .then(_ => {
                navigate(`/preference/${id}`)
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    async function getMessages() {
        if (id) {
            const response = await axios.get(`${constants.SERVER_API}/getPreferencePairs`,{
                params: {
                    id: id,
                }
            })
            if (response.data) {
                setPairs(response.data?.pairs)
            }
        }
    }

    useEffect(() => {
        getMessages()
    }, [])

    return (
        <Box sx={{padding: 2}}>
            <Box sx={{marginBottom: "2em"}}>
                <Typography variant="h5">
                        Knoll Usage Survey
                </Typography>
            </Box>
            <Grid container direction="column" spacing={2}>
                <Grid size={6}>
                    <Typography variant="body1">
                        Knoll allows users to integrate external knowledge (e.g., about CURIS summer internships, course selection) when interacting with commercial AI chatbots, such as ChatGPT or Claude.
                    </Typography>
                </Grid>
                {
                    keys.map((key, idx) => {
                        return (
                            <Grid size={6}>
                                <Typography>
                                    <strong>
                                        {surveyQuestions[key]}
                                    </strong>
                                </Typography>
                                <TextField
                                    id={key}
                                    fullWidth
                                    multiline
                                    required
                                    error={errors[key]}
                                    helperText={errors[key] ? "Response must be at least 10 words long." : null}
                                    rows={2}
                                    onChange={onUpdateSurvey}
                                />
                            </Grid>
                        )
                    })
                }
                <Grid size={6}>
                    <Typography>
                    <strong>
                        We are recruiting participants for a 30 minute interview on using Knoll. You will be compensated with a $20 Amazon gift card for your time. <br/>
                        <br/>
                        I consent to be contacted for an interview study.   
                    </strong>
                    </Typography>
                    <RadioGroup name="isInterview" onChange={changeRadio}>
                            <FormControlLabel value="Yes" control={<Radio size="small" />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio size="small" />} label="No" />
                    </RadioGroup>
                </Grid>
                <Grid size={10}>
                    <Button
                        variant="contained"
                        onClick={onSubmit}
                        disabled={checkDisabled() ? true : false}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}


{/* <Grid size={6}>
                    <Typography variant="body1">
                        <strong>
                            What types of prompts did you use with Knoll?
                        </strong>
                    </Typography>
                    <TextField
                        id="prompt-type"
                        fullWidth
                        multiline
                        required
                        error={errors['prompt-type']}
                        helperText={errors['prompt-type'] ? "Response must be at least 10 words long." : null}
                        rows={2}
                        onChange={onUpdateSurvey}
                    />
                </Grid >
                <Grid size={6}>
                    <Typography variant="body1">
                        <strong>
                            Would you have used these prompts without Knoll? Why or why not?
                        </strong>
                    </Typography>
                    <TextField
                        id="without-knoll"
                        fullWidth
                        multiline
                        required
                        error={errors['without-knoll']}
                        helperText={errors['without-knoll'] ? "Response must be at least 10 words long." : null}
                        rows={2}
                        onChange={onUpdateSurvey}
                    />
                </Grid > */}
                {/* <Grid size={6}>
                    < Typography>
                        <strong>
                            How much do you agree with the following statement?
                        </strong>
                    </Typography>
                    <Typography>
                        Using Knoll, I asked ChatGPT / Claude prompts that I would not have otherwise.
                    </Typography>
                    <RadioGroup>
                            <FormControlLabel value="1" control={<Radio size="small" />} label="Strongly disagree" />
                            <FormControlLabel value="2" control={<Radio size="small" />} label="Disagree" />
                            <FormControlLabel value="3" control={<Radio size="small" />} label="Neither agree nor disagree" />
                            <FormControlLabel value="4" control={<Radio size="small" />} label="Agree" />
                            <FormControlLabel value="5" control={<Radio size="small" />} label="Strongly agree" />
                    </RadioGroup>
                </Grid> */}
                // <Grid size={6}>
                //     <Typography>
                //         <strong>
                //             What other modules would you find useful to have for ChatGPT or Claude?
                //         </strong>
                //     </Typography>
                //     <TextField
                //         id='other-modules'
                //         fullWidth
                //         multiline
                //         required
                //         error={errors['other-modules']}
                //         helperText={errors['other-modules'] ? "Response must be at least 10 words long." : null}
                //         rows={2}
                //         onChange={onUpdateSurvey}
                //     />
                // </Grid>