import { Typography, Button, Box, Paper, CircularProgress} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CheckIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/Pending';

export default function ShareCard(props) {
    const module = props.module 
    const link = module.source === "google" ? module.doc_page : module.gh_page

    const openSource = () => {
        window.open(link, '_blank');
    }

    return (
        <Paper elevation={3} sx={{padding: "2em", width: "40vw"}}>
            <Grid container spacing={1} alignItems="center" sx={{ height: "100%"}}>
                <Grid size={{xs: 12}}>
                    <Grid container direction="column">
                        <Grid size={12}>
                            <Grid container spacing={2}>
                                <Grid>
                                    <Typography variant="h5">
                                        <strong>
                                            {module.name}
                                        </strong>
                                    </Typography>
                                </Grid>
                                <Grid size={{md: 1}}>
                                    <Button 
                                        size="small"
                                        sx={{width: "100%", fontSize:"0.75rem"}}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => openSource()}
                                    >
                                            Source
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={12}>
                            <Typography variant="body1">
                                {module.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{md: 4}}>
                    <Grid 
                        container 
                        direction="column"
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        justifyContent="space-around"
                        sx={{height: "100%", minHeight: "10vh"}}
                    >  
                        <Grid size={{md: 8}}>
                            {
                                props.showSuccess ? (
                                    <Button 
                                        size="small"
                                        sx={{width: "100%"}}
                                        variant="contained"
                                        startIcon={<CheckIcon/>}
                                    >
                                        Installed
                                    </Button>
                                ) : (
                                    <Button 
                                        size="small"
                                        sx={{width: "100%"}}
                                        variant="contained"
                                        onClick={() => props.onInstall()}
                                        loading={true}
                                        disabled={!props.isInstalled}
                                        endIcon={props.isLoading ? <CircularProgress size="25px"/> : null}
                                    >
                                        Add
                                    </Button>
                                )
                            }
                        </Grid>
                        <Grid size={{md: 8}}>
                            <Button 
                                size="small"
                                sx={{width: "100%"}}
                                variant="outlined"
                                onClick={() => props.toggleShow()}
                            >
                                See Examples
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}