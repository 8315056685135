import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Paper} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LoginButton } from '../components/LoginButton';
import { GuestButton } from '../components/GuestButton';
import theme from '../theme';


export function LoginAlert(props) {
    const { id } = useParams();

    return (
        <Paper
            elevation={0}
            severity="info"
            sx={{padding: "1em 3em 1em 1em", borderColor: theme.palette.dark.default, border: "1px solid", backgroundColor: "white", width: "40vw"}}
        >
            <Grid container direction="column" spacing={2}>
                <Grid>
                    <Typography variant="body2">
                        {props.message}
                    </Typography>
                </Grid>
            
            </Grid>                
        </Paper>
    )
}


// <Grid 
//                     container
//                     direction="row"
//                     justifyContent="flex-start"
//                     alignItems="flex-start"
//                 >
//                     {/* <Grid xs={4}>
//                         <LoginButton text={"Continue with Github"} link={'download'}/>
//                     </Grid> */}
//                     {
//                         props.showGuest ? (
//                             <Grid xs={4}>
//                                 <GuestButton text={"Get started"}/>
//                             </Grid>
//                         ) : null
//                     }
//                 </Grid>