const CHROME_ID = process.env.REACT_APP_EXTENSION_ID;
const CLIPPER_ID = process.env.REACT_APP_EXTENSION_ID2;
const SERVER_API=process.env.REACT_APP_SERVER_API;
const TEMPLATE_LINK="https://github.com/dorazhao99/community-module-template/tree/main";
const OPEN_AI = "https://chatgpt.com/";
const CHROME_LINK = "https://chromewebstore.google.com/detail/knoll/fmboebkmcojlljnachnegpbikpnbanfc"

export default {
    CHROME_ID, 
    CLIPPER_ID,
    SERVER_API,
    OPEN_AI,
    TEMPLATE_LINK,
    CHROME_LINK
}