import * as React from 'react';
import { Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

export function ContactUsPage() {
    return (
        <Grid container sx={{justifyContent: "center", alignItems: "center"}} direction="column">
            <Grid size={8} sx={{minHeight: "80vh"}}>
                <Typography variant="h5">
                    <strong>Contact Us</strong>
                </Typography>
                <Typography>
                    If you have any questions, please reach out to us at <a href="mailto:info@knollapp.com">info@knollapp.com</a>.
                </Typography>
                <Typography>
                    If you notice a bug, report it using our <a href="https://forms.gle/c62xrBqDVkPQ2YeK6" _target="blank">form</a>!
                </Typography>
            </Grid>
                    
        </Grid>
    );

}