import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { linkWithPopup, GithubAuthProvider } from "firebase/auth";
import { useSelector } from 'react-redux'
import { auth, provider } from '../firebase';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import constants from '../constants';

export function ShareClippingPage() {
    const userInfo = useSelector((state) => (state.user))
    const isLoggedIn = userInfo.info.uid ? true : false
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const navigate = useNavigate();


      
    const loginWithGitHub = () => {
        console.log(auth)
        if (auth.currentUser) {
            linkWithPopup(auth.currentUser, provider)
            .then(function (result) {
            console.log('Link result')
            const credential = GithubAuthProvider.credentialFromResult(result);
            const token = credential?.accessToken;
            const user = result.user;
            console.log('User', user)
            const data = {
                uid: user.uid,
                displayName: user.displayName, 
                githubID: user.providerData[0].uid,
            }
            axios.post(`${constants.SERVER_API}/createUser`, data, {
                headers: {
                    Authorization: `${token}`
                }
            })
            .then(result => {
                // send message to extension
                try {
                    chrome.runtime.sendMessage(
                        constants.CHROME_ID,
                        { user: user.uid, isAnon: user.isAnonymous, type: "sign_in" },
                        function(response) {
                            console.log(response)
                        }
                    );
                } catch(error) {
                    console.error(error)
                }
                const moduleName = localStorage.getItem('shared_module');
                console.log('Module', moduleName)
                if (moduleName) {
                    localStorage.removeItem('shared_module');
                    navigate(`/add/${moduleName}`)
                } else {
                    navigate(`/login-confirmation`)
                }
            })
            // The signed-in user info.
        })
        .catch(function (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            const credential = error.credential;
            if (errorCode === 'auth/credential-already-in-use') {
                navigate('/error')
            } else {
                console.error(error);
                navigate('/error')
            }
            });
        }
    };

    useEffect(() => {
        loginWithGitHub();
      }, [userInfo]); 

    return (
       <Box>
            <Typography variant="h5">
                <strong>
                    Link your account with Github
                </strong>
            </Typography>
            <Typography>
                {error}
                {success}
            </Typography>
       </Box>
    )
}


