import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import utils from '../utils.js';
import { Typography, Button, Box, Paper, Alert, IconButton} from '@mui/material';
import { signInAnonymously } from "firebase/auth";
import { auth } from '../firebase';
import { DownloadAlert } from '../components/DownloadAlert';
import { useParams } from 'react-router-dom';
import constants from "../constants";
import ShareCard from '../components/ShareCard';
import Grid from '@mui/material/Grid2';
import { addUser } from '../reducers/userReducer.js';
import { Helmet } from "react-helmet";

export function ErrorAlert(props) {
    return (
        <Box>
            {
                props.userInfo?.uid ? (
                    <Alert severity="error">
                        {props.error}
                    </Alert>
                ) : null
            }
        </Box>
    )
}


export function AddedModulePage() {
    const userInfo = useSelector((state) => (state.user))
    const dispatch = useDispatch()

    const isLoggedIn = userInfo.info.uid ? true : false
    const [isLoading, setLoading] = useState(true)
    const [isSuccess, setSuccess] = useState(false)
    const [showInfo, setShow] = useState(false)
    const [module, setModule] = useState({})
    const [isInstalled, setInstalled] = useState(false)
    const [error, setError] = useState(null)
    
    const [newUser, setNewUser] = useState(null)

    const { id } = useParams();
    const navigate = useNavigate();
    const copyContent = (text) => {
        navigator.clipboard.writeText(text);
    }

    const loginAnonymously = async () => {
        localStorage.setItem('shared_module', id);
        navigate('/get-started')
    };

    const helpAddModule = async(uid, id) => {
        try {
            const data = {
                uid: uid, 
                module: id
            }
            await axios.post(`${constants.SERVER_API}/selectModule`, data)
            .then(response => {
                if (response.data) {
                    const checked = response.data.checked 
                    const modules = response.data.modules

                    chrome.runtime.sendMessage(
                        constants.CHROME_ID,
                        { user: uid, type: "save", data: {checked: checked, modules: modules}},
                        function(response) {
                            console.log('save response')
                            setSuccess(true)
                            setLoading(false)
                        }
                    );
                }
            })
            .catch(error => {
                console.error(error)
                setLoading(false)
                navigate('/error')
            })
        } catch (err) {
            console.log(err)
            setLoading(false)
            navigate('/error')
        }
    }

    const addModule = async() => {
        // if user does not exist then create account for them
        if (!isLoggedIn) {
            await loginAnonymously()
        } else {
            if (id && userInfo.info.uid) {
                setLoading(true)
                helpAddModule(userInfo.info.uid, id)
                
            }
        }
    }

    const fetchModule = async () => {
        if (id) {
            await axios.get(`${constants.SERVER_API}/module`,{
                params: {
                    id: id,
                    user: userInfo.info.uid ? userInfo.info.uid : ''
                }
            })
            .then(response => {
                if (response.data) {
                    if (response.data.success && response.data.data) {
                        setModule(response.data.data)
                        if (response.data.data?.examples && response.data.data.examples.length > 0) {
                            setShow(true)
                        }
                        setError(null)
                    } else {
                        if (response.data.error) {
                            setError(response.data.error)
                        } else {
                            setError('Module was not found. Please try again later.')
                        }
                    }
                    setLoading(false)
                }
            })
        }
    };

    const directLLM = (provider) => {
        if (provider === 'openai') {
            window.open('https://chatgpt.com', '_blank');
        } else if (provider === 'claude') {
            window.open('https://claude.ai', '_blank');
        }
    }

    const toggleShow = () => {
        setShow(!showInfo)
    }
    
    useEffect(() => {
        utils.checkExtension(userInfo, setInstalled);
        fetchModule();
    }, []);


    return (
        <Box>
            <Helmet>
                <title> Add Module | {module.name ? module.name : ""}</title>
                <meta name="description" content={module.description ? module.description : "Add external knowledge to your LLM context"} />
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content="knollapp.com"/>
                <meta name="twitter:title" content={`Add Module | ${module.name ? module.name : ""}`}/>
                <meta name="twitter:description" content={module.description ? module.description : "Add external knowledge to your LLM context"}/>
                <meta name="twitter:image" content=""/>
            </Helmet>
            {
                <Grid container
                    direction="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                    sx={{height: "100%", width: "100%", padding: 0}}
                >
                    {
                        !isInstalled ? (
                            <DownloadAlert/>
                        ) : (
                            null
                        )
                    }
                    {
                        error ? (
                            <ErrorAlert error={error} uid={userInfo.info}/>
                        ) : (
                            <Grid xs={12}>
                                <Box sx={{marginBottom: "1em"}}>
                                    <ShareCard
                                        module={module}
                                        toggleShow={toggleShow}
                                        showSuccess={isSuccess}
                                        onInstall={addModule}
                                        isInstalled={isInstalled}
                                        isLoading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        )
                    } 
                    {
                        showInfo ? (
                            <Box sx={{marginBottom: "1em"}}>
                                <Paper elevation={3} sx={{padding: "2em", width: "40vw"}}>

                                    
                                        {
                                            module.examples && module.examples.length > 0 ? (
                                                <Grid container direction="column">
                                                <Grid>
                                                    <Typography variant="body1">
                                                        Try asking questions like this...
                                                    </Typography>
                                                </Grid>
                                                <Grid container direction="column"spacing={2} >
                                                        {
                                                            module.examples.map(example => {
                                                                return (
                                                                    <Grid>
                                                                        <Paper elevation={0} sx={{padding: "1em", border: "solid 1px #e2e2e2"}}>
                                                                            <Grid container alignItems="center" justifyContent="space-between" spacing={1} direction="row">
                                                                                <Grid item size={{ xs: 10, md: 8 }}>
                                                                                    <Typography variant="body2">
                                                                                        {example}
                                                                                    </Typography>
                                                                                    
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <IconButton onClick={() => copyContent(example)}>
                                                                                        <ContentCopyIcon/>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                            
                                                                            
                                                                        </Paper>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    
                                                </Grid>
                                                </Grid>
                                            ) : (
                                                <Typography>
                                                    Sorry, there are no examples at this time. Check back later.
                                                </Typography>
                                            )
                                        }
                                    
                                </Paper>
                            </Box>
                        ) : null
                    }
                    {
                        isSuccess ? (
                            <Box>
                                <Paper elevation={3} sx={{padding: "2em", width: "40vw", backgroundColor: "#f2f2f2", outline: "#e2e2e2 solid 1px"}}>
                                    <Typography variant="h6">
                                            You have successfully added {module.name ? `the module, ${module.name}` : "the selected module"}!
                                    </Typography>
                                    <Typography>
                                        Start chatting with your favorite LLM below and the knowledge will be inserted when relevant.
                                    </Typography>
                                    <Grid container direction="row" spacing={2} sx={{margin: "0.5em 0"}}>
                                        <Grid sx={3}>
                                            <Button 
                                                variant="contained"
                                                size="small"
                                                onClick={() => directLLM('openai')}
                                            >
                                                ChatGPT
                                            </Button>
                                        </Grid>
                                        <Grid sx={3}>
                                            <Button 
                                                variant="contained"
                                                size="small"
                                                onClick={() => directLLM('claude')}
                                            >
                                                Claude
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        ) : null
                    }
                </Grid>
            }
        </Box>
    );
}




 // console.log('Login as guest')
        // signInAnonymously(auth)
        // .then(function (result) {
        //     const user = result.user;
        //     console.log(user)
        //     const data = {
        //         uid: user.uid,
        //     }

        //     setNewUser(user.uid)
        //     axios.post(`${constants.SERVER_API}/createGuest`, data)
        //     .then(result => {
        //         console.log(result)
        //         try {
        //             chrome.runtime.sendMessage(
        //                 constants.CHROME_ID,
        //                 { user: user.uid, type: "sign_in" },
        //                 function(response) {
        //                     const update = {
        //                         uid: user.uid,
        //                         displayName: 'Guest'
        //                     }
        //                     dispatch(addUser(update))
        //                     helpAddModule(user.uid, id)
        //                 }
        //             );
        //         } catch(error) {
        //             console.error(error)
        //             navigate('/error')
        //         }
        //     })
        //     .catch(error => {
        //         console.error(error)
        //         navigate('/error')
        //     })
        // })
        // .catch((error) => {
        //     console.log(error)
        //     setError(error)
        // });