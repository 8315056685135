import React from 'react';
import { Typography, Button, Box, Paper, Chip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from "react-router-dom";
import Teaser from "../assets/teaser.svg"
import chromeStore from "../assets/chromeStore.png"
import { Footer } from '../components/Footer';
import "./homepage.css"

export function Header() {
    const navigate = useNavigate();

    const handleDownload = () => {
        window.open('https://chromewebstore.google.com/detail/knoll/fmboebkmcojlljnachnegpbikpnbanfc?hl=en-US&utm_source=ext_sidebar', '_blank')
    }

    return (
        <Grid>
            <Grid container alignItems={'center'} justifyContent={'space-around'} spacing={1}>
                <Grid size={{sm: 12, md: 7}}>
                    <Typography sx={{textAlign: "left"}} variant="h2">
                        <strong>
                            Building collective knowledge for large language models
                        </strong>
                    </Typography>
                    <Grid sx={{marginTop: "2em"}} container justifyContent={'flex-start'}>
                        <Grid size={10} alignItems={'center'}>
                            <Typography sx={{textAlign: "left"}} variant="h6">
                                Knoll is a research prototype that supports creating and incorporating dynamic, localized knowledge modules when interacting with AI tools.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className="buttonContainer" container justifyContent={'flex-start'}>
                        <Button className="explore" variant="outlined" onClick={() => navigate('/explore')}>
                            Start Exploring →
                        </Button>
                    </Grid>
                    <Grid className="buttonContainer" container justifyContent={'flex-start'}>
                        <Button className="download" variant="contained" onClick={() => handleDownload()}>
                            Download Knoll
                        </Button>
                    </Grid>
                    <Grid>
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                            <Grid>
                                <img className="chromeImage" src={chromeStore}/>
                            </Grid>
                            <Grid size={9}> 
                                <Typography variant="body1">
                                    Available in the Google Chrome Store
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{md: 12, lg: 4}} sx={{ display: { xs: 'none', md: 'inline' } }}>
                    <Grid container justifyContent="center">
                        <Grid size={{md: 6, lg: 12}}>
                            <img className="teaser" src={Teaser}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export function HomePage() {
    const navigate = useNavigate();

    const examples = [
        {
            "icon": "🤖",
            "title": "GUI Web Agents",
            "link": "add/usOIZ3O1QDxrmjsm8tbm",
            "source": "https://docs.google.com/document/d/1SZFLnmeT-RHSzbyQ3CE-DzvNgS_8lK-Ir6HiKRxXyIA/edit?tab=t.0#heading=h.aqxg17ut9lh1"
        }, 
        {
            "icon": "🧠",
            "title": "Research Elevator Pitches",
            "link": "add/j4ZTw0UWBE8qCgIbZqbB",
            "source": "https://github.com/dorazhao99/starter-modules/blob/main/research-elevator-pitch.md"
        }, 
        {
            "icon": "🍷",
            "title": "Restaurants and Bars in Barcelona",
            "link": "add/ucZyvy63qX75Qa0NZWO9",
            "source": "https://github.com/dorazhao99/starter-modules/blob/main/barcelona-restaurants.md"
        }
    ]
    const handleDownload = () => {
        window.open('https://chromewebstore.google.com/detail/knoll/fmboebkmcojlljnachnegpbikpnbanfc?hl=en-US&utm_source=ext_sidebar', '_blank');
    };

    const clickModule = (ev, link) => {
        const target = ev.target.id 
        if (target != "button") {
            navigate(link)
        }
    }

    return (
        <Box sx={{padding: '2em 0'}}>
            <Grid container spacing={{md: 12, xs: 4}} direction="column">
                    <Header/>
                    {/* <Grid size={12}> 
                        <Grid container direction="column"  alignItems={'center'} justifyContent={'space-around'} spacing={1}>
                            <Grid>
                                <Typography variant="h4" align="center">
                                    <strong>
                                    Create your own module ✎ 
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="h6" align="center">
                                    You can easily create your own knowledge module for your community, your organization, or just for yourself.<br/>
                                    Check out examples of existing modules and get started now. 
                                 </Typography>
                            </Grid>
                            <Grid sx={{margin: 2}} size={{xs: 10}}>
                                <Grid container spacing={1} justifyContent="space-between">
                                    {
                                        examples.map(example => {
                                            return (
                                                <Grid size={{xs: 12, md: 4}}>
                                                    <Paper 
                                                        className="example"
                                                        elevation={3} 
                                                        sx={{padding: 2}} 
                                                        onClick={(ev) => clickModule(ev, example.link)}
                                                    >
                                        
                                                        <Typography>
                                                            <strong>
                                                                <Chip label={example.icon} /> &nbsp;
                                                                {example.title}
                                                            </strong>
                                                        </Typography>
                                                        <Grid container sx={{mt: 1}} spacing={1}>
                                                            <Grid>
                                                                <Button id="button" variant="outlined" size="small" onClick={() =>window.open(example.source, '_blank')}>Source</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <Grid>
                                <Button className="download" variant="contained" onClick={() => navigate('create')}>
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Grid size={12}>
                        <Footer/>
                    </Grid>
            </Grid>
        </Box>
    );

}