import React from 'react';
import { useLottie } from 'lottie-react';

import { Typography, Button, Box } from '@mui/material';
import notfoundAnimation from '../assets/404.lottie.json'
import Grid from '@mui/material/Grid2';

import {
    useNavigate
} from "react-router-dom";

export function NotFoundPage() {
    const navigate = useNavigate();
    const options = {
        animationData: notfoundAnimation,
        loop: true
      };
    
    const { View } = useLottie(options);

    return (
        <Grid container alignItems="center" justifyContent="center" direction="column">
            <Grid textAlign="center">
                <Grid container alignItems="center" justifyContent="center" direction="column">
                    <Grid>
                        <Box sx={{width: '350px'}}>
                        {View}
                        </Box>
                    </Grid>
                    <Grid>
                        <Typography variant="h3">
                            <strong>404: Page Not Found</strong>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{margin: '2em 0'}}>
                <Button 
                    fullWidth 
                    sx={{width: "20em"}}
                    onClick={()=>navigate('/')} variant="contained"
                >
                    Take me home →
                </Button>
            </Grid>
        </Grid>
    );

}