import React from "react";
import { useLottie } from 'lottie-react';
import errorAnimation from '../assets/error.lottie.json'
import { Box, Typography, Button} from "@mui/material";
import Grid from '@mui/material/Grid2';

export function ErrorPage() {
    const options = {
        animationData: errorAnimation,
        loop: true
      };
    
    const { View } = useLottie(options);

    const openBugReport = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSfjB7zY4lH6jPOok0rsIu4Qbg2lVXMeJatyM3cReUQlUWV3bQ/viewform?usp=sharing", "_blank")
    }
    return (
        <Grid container justifyContent="center" alignItems="center" direction="column">
            <Grid>
                <Box sx={{width: '350px'}}>
                    {View}
                </Box>
            </Grid>
            <Grid>
                <Typography variant="h3">
                    <strong>
                        OOPS!
                    </strong>
                </Typography>
                <Typography variant="h6">
                    Looks like there was an error with our system. <br/>
                    File a bug report to help make Knoll better.
                </Typography>
            </Grid>
           <Grid>
            <Button variant="contained" onClick={openBugReport} sx={{mt: 1}}>
                    Report error
            </Button>
           </Grid>
        </Grid>
    )
}