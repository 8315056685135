import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import constants from '../constants';
import { Module } from '../components/Module';
import { useSelector } from 'react-redux'
import { LoginAlert } from '../components/LoginAlert';
import '../components/chips.css';

export function UserDetails(props) {
    return (
        <Box>
            <Grid container direction="column">
                <Grid>
                    <Typography variant="h6">
                        <strong>
                            My Modules
                        </strong>
                    </Typography>
                </Grid>
                {
                    props.userData?.modules.map((module, idx) => {
                        return(
                            <Grid size={8}>
                                <Module
                                    title={module.name}
                                    description={module.description}
                                    id={module.id}
                                    link={module.gh_page}
                                    deleteModule={props.deleteModule}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
export function UserPage() {
    const [userData, setUser] = useState({modules: [], checked: {}})
    const [error, setError] = useState(null)
    const [isLoggedIn, setLogged] = useState(null)
    const userInfo = useSelector((state) => (state.user))
    
    const fetchUser = async() => {
        const isLoggedIn = userInfo.info.uid ? true : false
        setLogged(isLoggedIn)
        if (isLoggedIn) {
            try {
                await axios.get(`${constants.SERVER_API}/userModule_v2`, 
                    { 
                        params: { user: userInfo.info.uid }
                    }
                )
                .then(response => {
                    if (response.data) {
                        setUser(response.data)
                    }
                })
            } catch (err) {
                console.log(err)
                setError(err.message);
            }
        }
    }

    const deleteModule = (id) => {
        const data = {uid: userInfo.info?.uid, module: id}
        axios.post(`${constants.SERVER_API}/removeModule`, data)
            .then(response => {
                if (response.data) {
                    if (response.data.success) {
                        let updatedModules = []
                        const responseModules = response.data.modules
                        userData.modules.forEach(module => {
                            if (responseModules.includes(module.id)) {
                                updatedModules.push(module)
                            }
                        })
                        const updatedUserData = {modules: updatedModules, checked: response.data.checked}
                        setUser(updatedUserData)
                    } else if (response.data.success === false) {
                        setError(response.data.message)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchUser()
    }, [userInfo]);

    return (
        <Box>
            {
                isLoggedIn ? (
                    <Box>
                        <UserDetails userData={userData} deleteModule={deleteModule}/>
                        {
                            error ? (
                                <Alert severity="error">There was an error deleting the module.</Alert>
                            ) : null
                        }
                    </Box>
                ) : (
                    <LoginAlert
                        message="You will need to authenticate via Github or continue as a guest to view this page."
                        showGuest={true}
                    />
                )
            } 
        </Box>
        // <Container>
        //     <Grid container> 
        //         <Box className="section">
        //                 <Typography variant="h3">
        //                 dorazhao9
        //                 </Typography>
        //         </Box>
        //     </Grid>
        //     <Grid size={7}>
        //         <Container>
        //             <Box className="section">
        //                 <Grid container spacing={2}>
        //                 <Grid>
        //                     <PeopleRoundedIcon fontSize="large"/>
        //                 </Grid>
        //                 <Grid>
        //                     <Typography variant="h5">
        //                         Communities
        //                     </Typography>
        //                 </Grid>
        //                 <Grid>
        //                     <Typography variant="h5">
        //                     1
        //                     </Typography>
        //                 </Grid>
        //                 </Grid>
        //                 <Grid container spacing={2}>
        //                 <Grid size={6}> 
        //                     <Paper sx={{padding: "1em", outline: "#e2e2e2 solid 1px"}} elevation={1} >
        //                     <Typography variant="body1">
        //                         Stanford HCI
        //                     </Typography>
        //                     <Grid container spacing={1}>
        //                         <Grid>
        //                         2 modules
        //                         </Grid>
        //                         &#183;
        //                         <Grid>
        //                         Updated Aug 10
        //                         </Grid>
        //                     </Grid>
        //                     </Paper>
        //                 </Grid>
        //                 </Grid>
        //             </Box>
        //             <Box>
        //             <Grid container spacing={2}>
        //                 <Grid>
        //                 <AutoAwesomeMotionRoundedIcon fontSize="large"/>
        //                 </Grid>
        //                 <Grid>
        //                     <Typography variant="h5">
        //                     Modules
        //                     </Typography>
        //                 </Grid>
        //                 <Grid>
        //                 <Typography variant="h5">
        //                     4
        //                 </Typography>
        //                 </Grid>
        //             </Grid>
        //             <Grid container spacing={2}>
        //                 <ModuleChip/>
        //                 <ModuleChip/>
        //                 <ModuleChip/>
        //                 <ModuleChip/>
        //                 {/* <ModuleChip/>
        //                 <ModuleChip/>
        //                 <ModuleChip/>
        //                 <ModuleChip/> */}
        //             </Grid>
        //             </Box>
        //         </Container>
        //     </Grid>
        // </Container>
    )
}