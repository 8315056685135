import React, { useState } from 'react';
import { Button, Typography, Box, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const FileUpload = (props) => {
  const [fileName, setFileName] = useState('')
  const [showInstructions, setShow] = useState(true)

  function checkKnowledge(string) {
    const match = string.match(/<cllm>(.*?)<\/cllm>/s); // 's' flag allows matching across multiple lines
    if (match && match[1].trim()) {
        return true
    } else {
        return false
    }
  }

  function preprocessFile(content) {
    const messages = []
    if (props.provider === 'openai') {
        content.forEach(convo => {
            const updateTime = convo?.create_time 
            if (updateTime > props.earliest) {
                if (convo.mapping) {
                    Object.keys(convo.mapping).forEach(idx => {
                        try {
                            const author = convo.mapping[idx].message.author.role 
                            if (author === 'user') {
                                const messageParts = convo.mapping[idx].message.content.parts
                                const selectedMessage = messageParts[messageParts.length - 1]
                                if (selectedMessage.length == 1) {
                                    messages.push(selectedMessage)
                                } else {
                                    if (selectedMessage.length > 0) {
                                        const injectedStr = messageParts[0]
                                        if (!checkKnowledge(injectedStr)) {
                                            messages.push(selectedMessage)
                                        }
                                    }
                                }
                            }
                        } catch(error) {
                            console.log(error)
                        }
                    })
                }
            }
        })
    } else if (props.provider === 'claude') {
        content.forEach(convo => {
            const updateTime = new Date(convo?.created_at).getTime(); 
            if (updateTime > props.earliest) {
                if (convo.chat_messages) {
                    convo.chat_messages.forEach(message => {
                        const author = message?.sender 
                        if (author === 'human') {
                            if (!checkKnowledge(message.text)) {
                                messages.push(message.text)
                            }
                        }
                    })
                }
            }
        })
    }

    props.setNum(Math.ceil(messages.length / 10))
    return messages
  }


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = JSON.parse(e.target.result);
          const processedContent = preprocessFile(content)
          props.setFileContent(processedContent);
          props.setDisplay(processedContent.slice(0, props.pageLimit))
          setFileName(file.name);
        } catch (error) {
            console.log(error)
          alert('Invalid JSON file. Please upload a valid .json file.');
        }
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a valid .json file.');
    }
  };

  const changeProvider = (event) => {
    props.setProvider(event.target.value)
  }

  return (
    <Box sx={{margin: 'auto', mt: 4 }}>
      {
        showInstructions ? (
            <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                <strong>
                    Upload Chat History
                </strong>
                </Typography>
                <Typography>
                    Instructions
                </Typography>
                <Typography variant="body2" sx={{mb: 3}}>
                    1. Export your chat data from the platform. See <a href="https://help.openai.com/en/articles/7260999-how-do-i-export-my-chatgpt-history-and-data" target="_blank">link</a> for exporting data from ChatGPT 
                    and  <a href="https://support.anthropic.com/en/articles/9450526-how-can-i-export-my-claude-ai-data" target="_blank">link</a> for exporting data from Claude.<br/>
                    <i>Note: exporting chat data is not available for Stanford organizational users on Claude.</i>
                    <br/>
                    2. Select the LLM provider that you exported data from
                    <br/>
                    3. Upload the <strong>conversations.json</strong> file from the exported data. We only import messages sent after February 5, 2025. 
                    <br/>
                    4. Feel free to remove messages you do not want to submit. Once you are happy, click the Submit button. You must submit at least 30 messages to qualify for the $20 compensation.
                </Typography>
                <Button variant="outlined" onClick={() => setShow(false)} size="small">
                        Close
                </Button>
        </Paper>
        ) : (
            <Button variant="outlined" onClick={() => setShow(true)} size="small">
                Show Instructions
            </Button>
        )
      }
        <Paper elevation={3} sx={{ padding: 2, mt: 1 }}>
        <FormControl variant="standard" size="small" sx={{mb: 2, width: "20vw"}}>
            <InputLabel>LLM Provider</InputLabel>
            <Select
                value={props.provider}
                label="LLM Provider"
                onChange={changeProvider}
            >
                <MenuItem value="openai">ChatGPT</MenuItem>
                <MenuItem value="claude">Claude</MenuItem>
            </Select>
        </FormControl>
        <Grid container alignItems="center" spacing={2}>
            <Grid>
                <input
                accept=".json"
                style={{ display: 'none' }}
                id="upload-json-file"
                type="file"
                onChange={handleFileChange}
                />
                <label htmlFor="upload-json-file">
                <Button
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<CloudUploadIcon />}
                >
                    Choose File
                </Button>
                </label>
            </Grid>
            {fileName && (
                <Grid>
                     <Typography variant="body2">
                        Selected File: <strong>{fileName}</strong>
                    </Typography>
                </Grid>
            )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default FileUpload;