import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, FormControl, FormGroup, Link, Checkbox, Alert, Tooltip, IconButton} from '@mui/material';
import { PreferencePairs } from '../components/PreferencePair';
import HelpIcon from '@mui/icons-material/Help';
import Grid from '@mui/material/Grid2';
import constants from '../constants';

export function TechnicalPreferencePage() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const split = params.get('split');
    const uid = params.get('uid');
    

    const navigate = useNavigate();
    const [pairs, setPairs] = useState({})
    const [modules, setModules] = useState({})
    const [relevance, setRelevance] = useState({})
    const [selected, setSelected] = useState({})
    const [isClicked, setClicked] = useState(false)
    const [error, setError] = useState({})
    const [idx, setIdx] = useState(0)
    const [isKnollLeft, setLeft] = useState([])
    const [currentSelected, setCurrent] = useState(undefined)

    const options = ["Response 1", "Response 2"]

    const selectedNum = (index) => {
        if (selected[index]) {
            console.log(selected[index])
            let sel = selected[index]
            if (sel === "knoll") {
                let value = isKnollLeft[index] ? "0" : "1"
                setCurrent(value)
            } else if (sel === "baseline") {
                let value = isKnollLeft[index] ? "1" : "0"
                setCurrent(value)
            } else {
                setCurrent(null)
            }
        } else {
            setCurrent(null)
        }
    }

    const handleCheckboxChange = (event) => {
        const value = event.target.name;
        if (idx in modules) {
            let prev= modules[idx]
            let newSelection = prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            const newState = {...modules, [idx]: newSelection}
            setModules(newState)
        } else {
            const newState = {...modules, [idx]: [value]}
            setModules(newState)
        }
      };
    
    const handleRelevanceChange = (event) => {
        const value = event.target.name;
        if (idx in relevance) {
            let prev= relevance[idx]
            let newSelection = prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            const newState = {...relevance, [idx]: newSelection}
            setRelevance(newState)
        } else {
            const newState = {...relevance, [idx]: [value]}
            setRelevance(newState)
        }
      };

    
    function onPrefClick(idx, isSelected) {
        selected[idx] = isSelected 
        setSelected(selected)
        setClicked(true)
    }

    function onPrev() {
        setIdx(idx - 1)
        selectedNum(idx - 1)
        setClicked(true)
        window.scrollTo(0, 0)
    }

    async function onNext() {
        const nextIdx = idx + 1 
        const numPairs = Object.keys(pairs).length
        if (nextIdx >= numPairs) {
            // process results 
            let updatedRelevance = {}
            Object.keys(relevance).forEach((r, i) => {
                updatedRelevance[r] = []
                relevance[r].forEach(response => {
                    if (response === "Response 1") {
                        updatedRelevance[r].push(isKnollLeft[i] ? "knoll" : "baseline")
                    } else {
                        updatedRelevance[r].push(isKnollLeft[i] ? "baseline" : "knoll")
                    }
                })
            })
            const data = {
                annotations: {
                    relevant_mods: modules, 
                    preferences: selected,
                    relevance: updatedRelevance
                },
                uid: uid,
                split: split
            }
            console.log(data)
            try {
                let response = await axios.post(`${constants.SERVER_API}/setAnnotations`, data)
                navigate(`/survey-complete`)
            } catch {
                console.error(error)
                setError({status: true, message: "Error saving your survey. Please contact Dora (dorothyz@stanford.edu)."})
            }

        } else {
            setIdx(nextIdx)
            selectedNum(nextIdx)
            if (!(nextIdx in modules)) {
                setClicked(false)
            }
            window.scrollTo(0, 0)
        }
    }

    async function getMessages() {
        const response = await axios.get(`${constants.SERVER_API}/getTechnicalPairs`,{
            params: {
                split: split,
            }
        })
        if (response.data) {
            setPairs(response.data.pairs)
            const sideAssignment = []
            Object.keys(response.data.pairs).forEach(pair => {
                sideAssignment.push(Math.floor(Math.random() * 2) == 0)
            })
            setLeft(sideAssignment)
        }
    }

    useEffect(() => {
        getMessages()
    }, [])

 

    return (
        <Box sx={{padding: 2}}>
            <Box sx={{marginBottom: "2em"}}>
                <Typography variant="h5">
                    Knoll Technical Evaluation
                </Typography>
            </Box>
            <Grid container direction="column" spacing={2}>
                <Grid size>
                    <Typography variant="h6">
                        Round {idx + 1} of {Object.keys(pairs).length}
                    </Typography>
                </Grid>
                <Grid size={12}>
                    <Typography variant="h6">
                        <strong>
                            Select the response that you prefer. 
                        </strong>
                    </Typography>
                </Grid>
                
                <Grid>
                    <PreferencePairs 
                        idx={idx}
                        pairs={pairs[idx] ? pairs[idx] : undefined}
                        setSelected={onPrefClick}
                        isKnollLeft={isKnollLeft[idx] ? isKnollLeft[idx] : undefined}
                        className={currentSelected}
                        type="technical"

                    />
                </Grid>
                <Grid>
                    <Grid container alignItems="center">
                        <Grid>
                            <Typography variant="h6">
                                <strong>
                                    Select all responses that are relevant to the prompt.
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid>
                            <Tooltip title="Is the answer relevant to the query and complete?">
                                <IconButton>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>  
                        </Grid>
                    </Grid>
                    <FormControl component="fieldset">
                        <FormGroup>
                        {options.map(
                            (option) => (
                                <Box>
                                    <Checkbox
                                        checked={relevance[idx] ? relevance[idx].includes(option) : false}
                                        onChange={(evt) => handleRelevanceChange(evt)}
                                        name={option}
                                    />
                                    {option}
                                </Box>
                            )
                        )}
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid>
                    <Typography variant="h6">
                        <strong>
                        Select the following modules that are relevant to answering the prompt.
                        </strong>
                    </Typography>
                    <FormControl component="fieldset">
                        <FormGroup>
                        {pairs[idx]?.modules.map(
                            (item, i) => (
                                <Box>
                                    <Checkbox
                                        checked={modules[idx] ? modules[idx].includes(item) : false}
                                        onChange={(evt) => handleCheckboxChange(evt)}
                                        name={item}
                                    />
                                    <Link href={pairs[idx].links[i]} target="_blank" rel="noopener">{item}</Link>
                                </Box>
                            )
                        )}
                        
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid size={12}>
                    <Grid container justifyContent="center" spacing={8}>
                        <Grid>
                            <Button
                                variant="contained"
                                onClick={onPrev}
                                disabled={idx === 0}
                            >
                                Prev
                            </Button>
                        </Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                onClick={onNext}
                                disabled={!isClicked}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    {
                        error.status ? (
                            <Alert severity="error">
                                {error.message}
                            </Alert>
                        ) : null
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

