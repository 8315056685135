import React, { useEffect, useState } from 'react';
import { GuestButton } from '../components/GuestButton';
import { Typography, Button, Box, Card, CardContent, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LoginButton } from '../components/LoginButton';
import { Link } from 'react-router-dom';

export function GuestPage() {
    return (
        <Box sx={{padding: '2em 0', height: '80%'}}>
            <Grid sx={{height: '50%'}} container justifyContent={'center'} alignItems={'center'}>
                <Grid size={7}>
                    <Card>
                        <CardContent>
                            <Grid container direction="column" justifyContent={'center'}>
                                {/* <Typography sx={{textAlign:"center"}} variant="h4">
                                    <strong>Continue as Guest</strong>
                                </Typography>
                                <Typography sx={{textAlign:"center"}} variant="body2">
                                    Already have an account? <Link to="/login">Log in.</Link>
                                </Typography> */}
                                <Typography variant="h5">
                                    <strong>
                                        Informed Consent
                                    </strong>
                                </Typography>
                                <Paper elevation={0} sx={{margin: "1em 0", backgroundColor: "#f4f4f4", boxShadow: "inset 0 0 4px #d0d0d0"}}>
                                    <Box sx={{padding: "1em", maxHeight: "15em", overflowY: "scroll"}}>
                                        <Typography variant="body2">
                                                <strong>DESCRIPTION: </strong>
                                                Knoll is a research prototype used for creating and using collective knowledge repositories for large language models. You can download the Chrome browser extension and select knowledge repositories to use with ChatGPT. When using this system, we will record the following information: number of messages that you send to ChatGPT, history of knowledge repositories you have added and used, and prompts when modules are activated. You will also receive an optional survey on your experience using Knoll and may be invited for a follow-up interview. If you choose to participate in the optional survey and interview, this information will be linked to your usage data. 
                                        </Typography>
                                        <br/>
                                        <Typography variant="body2">
                                                <strong>TIME INVOLVEMENT: </strong>
                                                There is no set time involvement for using Knoll. If you choose to participate in the optional survey, this will take approximately 5-10 minutes.                                        </Typography>
                                        <br/>
                                        <Typography variant="body2">
                                                <strong>RISKS AND BENEFITS: </strong>
                                                While we strive to create lower risks with this project, risks associated with the study are similar in nature for those using large language models (e.g., ChatGPT, Claude, Gemini). You also will be asked to enable at least one knowledge repository and have the opportunity to create your own knowledge repositories. Note that these knowledge repositories are created by other users and, although unlikely, could contain malicious or undesired content. You are able to view the contents of the repository before enabling it. When creating knowledge repositories, please only include information you would be comfortable sharing with ChatGPT. 

                                                Study data will be stored securely, in compliance with Stanford University standards, minimizing the risk of confidentiality breach. The benefits which may reasonably be expected to result from this study are similar in nature to using other large language models. We cannot and do not guarantee or promise that you will receive any benefits from this study.
                                        </Typography>
                                        <br/>
                                        <Typography variant="body2">
                                                <strong>PAYMENTS: </strong>
                                                You will not receive any direct compensation for using Knoll.
                                                For participants who complete the optional survey, you will receive a $10 Amazon gift card. 
                                        </Typography>
                                        <br/>
                                        <Typography variant="body2">
                                                <strong>PARTICIPANT'S RIGHTS: </strong>
                                                If you have read this form and have decided to
                                                participate in this project, please understand your <i>participation is voluntary</i> and
                                                you have the <i>right to withdraw your consent or discontinue participation at
                                                any time without penalty or loss of benefits to which you are otherwise
                                                entitled.</i> The alternative is not to participate. You have the right to refuse to
                                                answer particular questions. The results of this research study may be presented at
                                                scientific or professional meetings or published in scientific journals. Your individual
                                                privacy will be maintained in all published and written data resulting from the study.
                                                In accordance with scientific norms, the data from this study may be used or shared with
                                                other researchers for future research (after removing personally identifying information)
                                                without additional consent from you.
                                        </Typography>
                                        <br/>
                                        <Typography variant="body2">
                                                <strong>CONTACT INFORMATION: </strong>
                                                Questions: If you have any questions, concerns or complaints about this research, 
                                                its procedures, risks and benefits, contact the Protocol Director, Dora Zhao at the 
                                                email dorothyz@stanford.edu. 
                                                <br/><br/>
                                                Independent Contact: If you are not satisfied with how this study is being conducted,
                                                or if you have any concerns, complaints, or general questions about the research or your
                                                rights as a participant, please contact the Stanford Institutional Review Board (IRB)
                                                to speak to someone independent of the research team at 650-723-2480 or 
                                                toll free at 1-866-680-2906, or email at irbnonmed@stanford.edu.
                                                You can also write to the Stanford IRB, Stanford University, 1705 El Camino Real,
                                                Palo Alto, CA 94306.
                                        </Typography>
                                    </Box>
                                </Paper>
                                
                                <Typography variant="body2">
                                    By continuing, I give consent to take part in this study.
                                </Typography>
                                <GuestButton variant="contained" text={"Continue"}/>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}