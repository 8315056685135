import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  info: {}, 
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action) => {
        if (action.payload) {
          state.info["uid"] = action.payload.uid
          state.info["name"] = action.payload.displayName
        }
    },
    deleteUser: (state, action) => {
        delete state.info["uid"]
        delete state.info["name"]
    }
  },
})

export const { addUser, deleteUser } = userSlice.actions
export default userSlice.reducer