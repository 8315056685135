import React, { useState}  from 'react';
import { Typography, Paper, IconButton, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Chatbot } from '../components/Chatbot';
import { PromptSelector } from '../components/PromptSelector';
import Grid from '@mui/material/Grid2';

export function ExamplePage() {
    const [checked, setChecked] = useState(true);
    const [starter, setStarter] = useState("")
    const [selected, setSelected] = useState("")
    const starterPrompts = [
        "Test 1", 
        "Test 2", 
        "Test 3",
        "Write your own prompt"
    ]

    const changeStarter = (idx) => {
        const selectedPrompt = starterPrompts[idx]
        setSelected(idx)
        if (idx < starterPrompts.length - 1) {
            setStarter(selectedPrompt)
        } else {
            setStarter('')
        }
    }

    const chatWidth = checked ? 8 : 11
    return (
        <Box sx={{display: "flex", width: "100%", height: "100%"}}>
            <Grid container sx={{height: "100%" , width: "100%"}}>
                {
                    checked ? (
                        <Grid size={4} sx={{marginLeft: "-4em"}}>
                            <Paper sx={{height: "98%", backgroundColor: "#FCFCFC"}}>
                                <Grid container sx={{height: "100%", padding: "1em 0 1em 1em"}}>
                                    <Grid size={11}>
                                        <Box>
                                            <Typography variant="h5">
                                               <strong>Name</strong> 
                                            </Typography>
                                            <Typography variant="body">
                                                Description
                                            </Typography>
                                            <Divider sx={{margin: "0.5em 0 1em 0"}}/>
                                            <Box>
                                                <Typography variant="h6">
                                                Try one of these prompts...
                                                </Typography>
                                                <Box>
                                                    <PromptSelector 
                                                        select={changeStarter} 
                                                        prompts={starterPrompts}
                                                        selected={selected}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid size={1}>
                                        <Grid container sx={{height: "100%"}} alignItems={"center"} justifyContent={"flex-end"}>
                                            <IconButton onClick={()=> setChecked(!checked)}>
                                                <ChevronLeftIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ) : (
                        <Grid sx={{marginLeft: "-4em"}} alignItems={"center"}>
                            <Paper sx={{height: "98%", backgroundColor: "#FCFCFC"}}>
                                <Grid container sx={{height: "100%"}} alignItems={"center"}>
                                    <IconButton onClick={()=> setChecked(!checked)}>
                                        <ChevronRightIcon/>
                                    </IconButton>
                                    
                                </Grid>
                            </Paper>
                        </Grid>

                    )
                }
                
                <Grid size={chatWidth}>
                    <Chatbot idx sx={{width: "100%"}} inputValue={starter} setInputValue={setStarter}/>
                </Grid>
            </Grid>
        </Box>
    );

}