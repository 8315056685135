import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button, Stack, Checkbox, FormControl, FormGroup, Link} from '@mui/material';
import { PreferencePairs } from '../components/PreferencePair';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import constants from '../constants';

export function PreferencePage() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [pairs, setPairs] = useState({})
    const [modules, setModules] = useState({})
    const [selected, setSelected] = useState({})
    const [className, setClassName] = useState(undefined)
    const [isClicked, setClicked] = useState(false)
    const [idx, setIdx] = useState(0)


    function onPrefClick(idx, isSelected) {
        selected[idx] = isSelected 
        setSelected(selected)
        setClicked(true)
    }

    const handleCheckboxChange = (event) => {
        const value = event.target.name;
        if (idx in modules) {
            let prev= modules[idx]
            let newSelection = prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            const newState = {...modules, [idx]: newSelection}
            setModules(newState)
        } else {
            const newState = {...modules, [idx]: [value]}
            setModules(newState)
        }
      };

    function onNext() {
        const nextIdx = idx + 1 
        const numPairs = Object.keys(pairs).length
        if (nextIdx >= numPairs) {
            const data = {
                'uid': id,
                'pairs': selected,
                'relevant': modules
            }
            axios.post(`${constants.SERVER_API}/submitSurvey`, data)
            .then(_ => {
                navigate(`/donate/${id}`)
            })
            .catch(error => {
                console.log(error)
            })
            
        } else {
            setClicked(false)
            setClassName(undefined)
            setIdx(nextIdx)

        }
    }

    async function getMessages() {
        if (id) {
            const response = await axios.get(`${constants.SERVER_API}/getPreferencePairs`,{
                params: {
                    id: id,
                }
            })
            if (response.data) {
                setPairs(response.data?.pairs)
            }
        }
    }

    useEffect(() => {
        getMessages()
    }, [])

    return (
        <Box sx={{padding: 2}}>
            <Box sx={{marginBottom: "2em"}}>
                <Typography variant="h5">
                        Knoll Usage Survey
                </Typography>
            </Box>
            <Grid container direction="column" spacing={2}>
                <Grid size={12}>
                    <Typography variant="h6">
                        Which response do you prefer?
                    </Typography>
                </Grid>
                <Grid>
                    <PreferencePairs 
                        idx={idx}
                        pairs={pairs[idx] ? pairs[idx] : undefined}
                        setSelected={onPrefClick}

                    />
                </Grid>
                <Grid>
                    <Typography variant="h6">
                        <strong>
                        Select the following modules that are relevant to answering the prompt.
                        </strong>
                    </Typography>
                    <FormControl component="fieldset">
                        <FormGroup>
                        {pairs[idx]?.names.map(
                            (item, i) => (
                                <Box>
                                    <Checkbox
                                        checked={modules[idx] ? modules[idx].includes(item) : false}
                                        onChange={(evt) => handleCheckboxChange(evt)}
                                        name={item}
                                    />
                                    <Link href={pairs[idx].links[i]} target="_blank" rel="noopener">{item}</Link>
                                </Box>
                            )
                        )}
                        
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid size={10}>
                    <Button
                        variant="contained"
                        onClick={onNext}
                        disabled={!isClicked}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

