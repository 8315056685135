import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LoginButton } from '../components/LoginButton';
import { GuestButton } from '../components/GuestButton';
import { useSelector } from 'react-redux'
import constants from '../constants';

export function DownloadedPage() {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => (state.user))
    const [redirect, setRedirect] = useState(false)
    useEffect(() => {
        const moduleName = localStorage.getItem('shared_module');
        if (moduleName) {
            localStorage.removeItem('shared_module');
            navigate(`/add/${moduleName}`)
        }
    }, []); 

    return (
        <Box sx={{padding: '2em 0', height: '80%'}}>
            {userInfo?.info.uid ? 
                (
                    <Grid container direction="column" justifyContent={'space-around'} alignItems={'center'} spacing={4}>
                        <Grid size={6}>
                            <Typography variant="h4">
                                <strong>
                                    Installation Complete! <br/>
                                    <br/>
                                </strong>
                            </Typography>
                            <Typography variant="h6">
                                Explore featured knowledge modules to use.
                            </Typography>
                            <Button onClick={() => navigate('/explore')} variant="contained">
                                Explore
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent="center">
                        <Grid size={8}>
                            <Grid container direction="column" justifyContent={'space-around'} alignItems={'flex-start'} spacing={2}>
                            <Grid size={6}>
                                <Typography variant="h4">
                                    <strong>
                                        Congrats!
                                    </strong>
                                </Typography>
                                <Typography variant="h5">
                                    You have installed the Knoll browser extension.
                                </Typography>
                                {/* <Typography variant="h6">
                                    Log in or sign up now to start adding your own knowledge when using ChatGPT or Claude. 
                                </Typography> */}
                            </Grid>
                            {/* <Grid size={6}>
                                <LoginButton text={"Continue with Github"} link={'download'}/>
                            </Grid> */}
                            <Grid size={2}>
                                <Button 
                                    fullWidth
                                    variant="contained"
                                    onClick={() => navigate('/get-started')}
                                >
                                    Get started &rarr;
                                </Button>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Box>
    );
}