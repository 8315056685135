import React, { useState } from 'react';
import { Box, IconButton, Modal, Paper, Typography, TextField, Alert } from '@mui/material';
import config from '../config';
import Grid from '@mui/material/Grid2';
import IosShareIcon from '@mui/icons-material/IosShare';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';

export function ShareModule(props) {
    const [isOpen, setOpen] = useState(false)
    const [isCopied, setCopied] = useState(false)
    const link = `${config.location}/add/${props.id}`

    const copyLink = () => {
        navigator.clipboard.writeText(link);
        setCopied(true)
    }
    return (
        <Box>
            <IconButton
                onClick={() => setOpen(true)}
            >
                <IosShareIcon/>
            </IconButton>
            <Modal
                open={isOpen}
                onClose={() => setOpen(false)}
            >
                <Grid container alignItems="center" justifyContent="center" sx={{height: "100%"}}>
                    <Grid size={6}>
                        <Paper elevation={3} sx={{padding: "2em", minHeight: "10vh"}}>
                            <Grid container direction="column" spacing={1}>
                                <Grid>
                                    <Grid container justifyContent="space-between">
                                        <Grid xs={9}>
                                            <Typography variant="h6">
                                                <strong> Share this module </strong>
                                            </Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                            <IconButton size="small" onClick={() => setOpen(false)}>
                                                <CloseIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid size={10}>
                                    <TextField
                                        fullWidth
                                        slotProps={{
                                        input: {
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <LinkIcon />
                                            </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => copyLink()}>
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        },
                                        }}
                                        defaultValue={link}
                                        variant="standard"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid size={8}>
                                    {
                                        isCopied ? (
                                            <Alert>
                                                Link copied!
                                            </Alert>
                                        ) : null
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        </Box>
    );
};

