import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button, IconButton, Pagination, TextField} from '@mui/material';
import Grid from '@mui/material/Grid2';
import FileUpload from '../components/FileUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import constants from '../constants';

export function DonatePage() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [fileContent, setFileContent] = useState(null);
    const [displayContent, setDisplay] = useState(null);
    const [page, setPage] = useState(1)
    const [email, setEmail] = useState('')
    const [numPages, setNum] = useState(1)
    const [provider, setProvider] = useState('openai')
    const [showDonate, setDonate] = useState(1)
    const EARLIEST = 1738732716 // earliest time for users 
    const pageLimit = 10 

    function deleteMessage(idx) {
        let copyPage = page
        const messageIdx = (copyPage - 1) * pageLimit + idx
        fileContent.splice(messageIdx, 1)
        const totalPages = Math.ceil(fileContent.length / pageLimit)
        setNum(totalPages)
        if (totalPages < page) {
            setPage(totalPages)
            copyPage = copyPage - 1
        }

        const start = (copyPage - 1) * pageLimit
        const newContent = fileContent.slice(start, start + pageLimit)
        setFileContent(fileContent)
        setDisplay(newContent)

    }

    function submitMessage() {
        const messageName = `message_${provider}`
        const data = {
            uid: id, 
            email_compensation: email
        }
        data[messageName] = fileContent

        axios.post(`${constants.SERVER_API}/submitSurvey`, data)
        .then(results => {
            navigate(`/survey-complete`)
        })
        .catch(error => {
            console.log(error)
        })
    }

    const updateEmail = (evt) => {
        setEmail(evt.target.value)
    }

    const updateDonate = (evt) => {
        setDonate(evt.target.value)
    }
    
    const handleChange = (evt, value) => {
        const start = (value - 1) * pageLimit
        const end = start + pageLimit
        setPage(value)
        setDisplay(fileContent.slice(start, end))
    };


    return (
        <Box sx={{padding: 2}}>
            <Grid size={6}>
                <Paper sx={{padding: "1em"}} elevation={2}>
                    <Typography variant="h6">
                        <strong>
                            Help contribute chat data! 
                        </strong>
                    </Typography>
                    <Typography>
                        We are also asking users if they are willing to contribute part of their ChatGPT or Claude chat history. The chat history will <strong>only</strong> be used for evaluating the performance of Knoll. 
                        We will compensate users with a $20 Amazon gift card. 
                    </Typography>
                    <br/>
                    <Typography>
                        Are you interested in donating your chat history?
                    </Typography>
                    <RadioGroup
                        value={showDonate}
                        onChange={updateDonate}
                        name="donate"
                        row
                    >
                        <FormControlLabel value={1} control={<Radio />} label="Yes" />
                        <FormControlLabel value={0} control={<Radio />} label="No" />
                    </RadioGroup>
                </Paper>
            </Grid>
            {
                showDonate == 1 ? (
                    <Box>
                        <FileUpload
                            fileContent={fileContent}
                            setFileContent={setFileContent}
                            setDisplay={setDisplay}
                            setNum={setNum}
                            earliest={EARLIEST}
                            pageLimit={pageLimit}
                            provider={provider}
                            setProvider={setProvider}
                        />
                        {displayContent && (
                            <Grid container sx={{mt: 2}} direction="column" spacing={1} justifyContent="center" alignItems="center">
                            <Grid size={12}>
                                <Typography variant="h6" gutterBottom>
                                Messages (Total #: {fileContent.length})
                                </Typography>
                            </Grid>
                            <Grid>
                                <Box sx={{maxHeight: "50vh", overflowY: "scroll", padding: 1}}>
                                    <Grid container direction="column" spacing={1} alignItems="center" justifyContent="center">
                                        {
                                            displayContent.map((message, idx) => {
                                                return (
                                                    <Grid size={12}>
                                                        <Paper sx={{padding: "1em", width: "75vw"}}>
                                                            <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                                                <Grid size={10}>
                                                                    
                                                                        <Typography>
                                                                            {message}
                                                                        </Typography>
                                                                </Grid>
                                                                <Grid size={1}>
                                                                    <IconButton>
                                                                        <DeleteOutlineIcon onClick={() => deleteMessage(idx)}/>
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                )
                                                
                                            })
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid>
                                <Pagination page={page} count={numPages} onChange={handleChange}/>
                            </Grid>
                            
                            <Grid>
                                <Paper elevation={3} sx={{mt: 1, mb: 1, padding: 2, outline: "1px solid #e2e2e2", backgroundColor: "#f5f5f5"}}>
                                    {/* <Box sx={{padding: 1}}>
                                        <Typography>
                                            Please input your email. We will use this email to send your compensation. 
                                        </Typography>
                                        <TextField
                                            sx={{backgroundColor: "white"}}
                                            fullWidth
                                            size="small"
                                            label="Email"
                                            onChange={updateEmail}
                                        />
                                    </Box> */}
                                <Button 
                                    fullWidth
                                    variant="contained"
                                    disabled={fileContent.length < 30}
                                    onClick={submitMessage}
                                >
                                    Submit
                                </Button>
                                </Paper>
                            </Grid>
                            </Grid>
                        )}
                    </Box>
                ) : (
                    <Box sx={{padding: 1}}>
                        <Button 
                            
                            variant="contained"
                            onClick={() => navigate(`/survey-complete`)}
                        >
                            Submit
                        </Button>
                    </Box>
                )
            }
        </Box>
    )
}