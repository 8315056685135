import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ShareModule } from '../components/ShareModule';
import { Typography, Paper, IconButton, Chip, Checkbox, Button} from '@mui/material';
import { useNavigate } from "react-router-dom";
import './module.css'; 

export function Module(props) {

    const navigate = useNavigate();
    const [selected, setSelected] = useState(false)

    const handleClick = () => {
        window.open(props.link, '_blank');
    };

    const handleRedirect = () => {
        window.open(`/add/${props.id}`, '_blank')
    }

    return (
        <Paper className="module">
            <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                <Grid size={9} className="info" onClick={handleRedirect}>
                    <Typography variant="body1" align="left">
                        <strong>{props.title}</strong>

                    </Typography>
                    <Typography align="left" variant="body2">
                        {props.description}
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <Grid container alignItems="center">
                        {
                            props.isUser && props.isInstalled ? (
                                <Grid xs={2}>
                                {
                                    props.isAdded ? (
                                        <IconButton>
                                            <CheckCircleIcon/>
                                        </IconButton>
                                    ) : (
                                        <Button 
                                            id={props.id}
                                            variant="outlined" 
                                            onClick={props.addModule}
                                            size="small"
                                        >
                                            ADD
                                        </Button>
                                    )
                                }
                                </Grid>
                            ) : (
                                
                                props.learnMore ? (
                                    <Grid xs={2}>
                                        <Button variant="text" size="small" onClick={handleRedirect}>
                                            <ChevronRightIcon/>
                                        </Button>
                                    </Grid>
                                ) : null
                            )
                        }
                        {
                            props.isShare ? (
                                <Grid xs={1}>
                                    <ShareModule id={props.id}/>
                                </Grid>
                            ) : null
                        }   
                        {
                            props.deleteModule ? (
                                <Grid xs={1}>
                                    <IconButton onClick={() => props.deleteModule(props.id)}>
                                        <DeleteOutlineIcon/>
                                    </IconButton>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );


}