import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Typography, Paper} from '@mui/material';
import Markdown from 'react-markdown'
import utils from '../utils';

import './pairs.css'; 

export function PreferencePairs(props) {
    const [isKnollLeft, setLeft] = useState(undefined)
    const [className, setClassName] = useState(undefined)

    const markdownStyle = {
        h1: 'h2',
        h2: 'h3',
        h3: 'h4'
    }

    const onClick = (num)=> {
        let selected = null
        if (isKnollLeft) {
            selected = num === "0" ? "knoll" : "baseline"
        } else {
            selected = num === "1" ? "knoll" : "baseline"
        }
        if (selected) {
            props.setSelected(props.idx, selected)
            setClassName(num)
        }
    }

    useEffect(() => {
        if (props.type === 'technical') {
            setLeft(props.isKnollLeft)
            setClassName(props.className)
        } else {
            console.log('here', props)
            setLeft(Math.floor(Math.random() * 2) == 0)
            setClassName(undefined)
        }
    }, [props.idx])

    return (
       <Box>
            {
                props.pairs ? (
                    <Grid container spacing={2}>
                                    <Grid size={12}>
                                        <Paper sx={{p: 2, backgroundColor: "#e3e3e3"}}>
                                            <Typography variant="body2">
                                                <strong>
                                                    PROMPT
                                                </strong>
                                            </Typography>
                                            <Markdown components={{markdownStyle}} style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
                                                {utils.parseLines(props.pairs?.prompt)}
                                            </Markdown>
                                        </Paper>
                                    </Grid>
                                    <Grid>
                                        <Grid container spacing={2} justifyContent="space-around">
                                            <Grid size={6}>
                                                <Paper className={className === "0" ? "selected" : "response"} elevation={3} sx={{p: 2, overflowX: "auto", wordWrap: 'break-word' }} onClick={() => onClick("0")}>
                                                    <Typography variant="body2">
                                                        <strong>
                                                            RESPONSE 1
                                                        </strong>
                                                    </Typography>
                                                    <Markdown components={{markdownStyle}} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                        {isKnollLeft ? utils.parseLines(props.pairs?.w_knoll) : utils.parseLines(props.pairs?.wo_knoll) }
                                                    </Markdown>
                                                </Paper>
                                            </Grid>
                                            <Grid size={6}>
                                                <Paper className={className === "1" ? "selected" : "response"} elevation={3} sx={{p: 2, overflowX: "auto", wordWrap: 'break-word'}} onClick={() => onClick("1")}>
                                                    <Typography variant="body2">
                                                        <strong>
                                                            RESPONSE 2
                                                        </strong>
                                                    </Typography>
                                                    <Markdown components={{markdownStyle}}style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                        {isKnollLeft ? utils.parseLines(props.pairs?.wo_knoll) : utils.parseLines(props.pairs?.w_knoll) }
                                                    </Markdown>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                ) : null
            }
       </Box>
    );


}
