import React, { useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import { useSelector } from 'react-redux'
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import BentoIcon from '@mui/icons-material/Bento';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton, Button, AppBar, Drawer, Toolbar, Typography, Menu, MenuItem, ListItemButton} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { signOut} from "firebase/auth";
import { auth } from '../firebase';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from "../assets/logo.svg"
import constants from "../constants";
import './module.css'; 

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

export function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(true);


  const container = window !== undefined ? () => window().document.body : undefined;

  const userInfo = useSelector((state) => (state.user))
  let substringName = undefined
  try {
    substringName = userInfo?.info?.name.substring(0, 1)
  } catch {
    substringName = undefined
  }
  const [anchorEl, setAnchorEl] = useState(false);
  const open = anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const navigate = useNavigate();
  const handleChat = () => {
    navigate('/chat')
  }

  const handleSettings = () => {
    navigate('/explore')
  }

  const handleCreate = () => {
    navigate('/create')
  }

  const handleLogin = () => {
    navigate('/login')
  }

  const handleAdd = () => {
    navigate('/add')
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const signOutGithub = () => {
    signOut(auth).then(() => {
         navigate('/logout')
         chrome.runtime.sendMessage(
             constants.CHROME_ID,
             { type: "sign_out" }
         );
    }).catch((error) => {
        console.log(error)
    });
};


  return (
    <Box>
       <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar   
            sx={{padding: 0}}
          >
            <Typography className="navExplore" variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => navigate('/')}>
                <img className="logo" src={Logo} alt="logo" />&nbsp;<strong> Knoll</strong>
             </Typography>
            {/* { userInfo?.info.uid ? (
                <IconButton style={{color:"bababa"}} onClick={handleChat}>
                  <ChatIcon/>
                  <Typography variant="body1">
                    &nbsp;Chat
                  </Typography>
                </IconButton>
              ):null
            } */}
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <IconButton 
                disableRipple
                disableFocusRipple
                className="navExplore" 
                onClick={handleSettings}
              >
                  <Typography variant="body1">
                    Explore
                  </Typography>
              </IconButton>
              <IconButton
                disableRipple
                disableFocusRipple
                onClick={() => navigate('/instructions')}
              >
                  <Typography className="navExplore" variant="body1">
                    Instructions
                  </Typography>
              </IconButton>
              <IconButton
                disableRipple
                disableFocusRipple
                onClick={() => navigate('/contact')}
              >
                  <Typography className="navExplore" variant="body1">
                    Contact
                  </Typography>
              </IconButton>
              { userInfo?.info.uid ? 
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCreate}
                    sx={{ml: 1, mr: 1}}
                  >
                    Add a Module 
                </Button>
                : null
              }
              { userInfo?.info.uid ? (
                <Box sx={{marginLeft: "1em"}}>
                  <Avatar
                    className="avatarButton"
                    sx={{ width: 36, height: 36, bgcolor: "#7091E6" }}
                    onClick={handleClick}
                    alt={userInfo?.info?.name}
                  >
                    {substringName}
                  </Avatar>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={() => navigate('/user')}>
                      <ListItemIcon>
                        <BentoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        My Modules
                      </ListItemText>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={signOutGithub}>
                      <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        Sign out
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
                ):null
              }
            
            </Box>
          </Toolbar>
        </AppBar>

    </Box>
  );
}
