import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box} from '@mui/material';
import { useParams } from 'react-router-dom';
import constants from "../constants";




export function InstallPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const checkExtension = () => {
        try {
            chrome.runtime.sendMessage(
                constants.CHROME_ID,
                { type: "check_exists" },
                function(response) {
                    if (response) {
                        navigate(`/add/${id}`)
                    }
                }
            );
        } catch(error) {
            console.error(error)
        }
    }

    useEffect(() => {
        checkExtension();
    }, []);

    return (
        <Box>
            <Typography>
                You need to install the Knoll browser extension to use this module. 
            </Typography>
        </Box>
    );

}