import React from "react";
import { Box, Typography, Button} from "@mui/material";
import Grid from '@mui/material/Grid2';

export function PersonalModulePage() {
    return (
        <Grid container justifyContent="center" alignItems="center" direction="column">
            <Grid>
                <Typography variant="h5">
                    <strong>
                        Your Personal Knowledge Module
                    </strong>
                </Typography>
                <Typography variant="body1">
                    Navigate to the "Personal Module" tab of the Knoll extension to view the knowledge you have added.
                </Typography>
            </Grid>
        </Grid>
    )
}