import React, { useState } from 'react';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  List,
  ListItem,
  IconButton,
  InputAdornment,
  Select,
  MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import constants from '../constants';
import utils from '../utils.js';
import { ChatBubble } from './ChatBubble.js'
import axios from 'axios';
import { useSelector } from 'react-redux'
import './module.css'
export function KnowledgeButton() {
  const knowledge = useSelector((state) => (state.knowledge))
  const [showKnowledge, setShow] = useState(false); 
  
  return (
    showKnowledge ? (
      <Card>
          <IconButton onClick={() => setShow(false)}>
            <CloseIcon/>
          </IconButton>
        <CardContent>
          {utils.formatKnowledge(knowledge)}
        </CardContent>
      </Card>
  ) : (
    <Button onClick={() => setShow(true)}>
      Show Knowledge
    </Button>
  )
  )
}
export function Chatbot(props) {
  const knowledge = useSelector((state) => (state.knowledge))
  const [model, changeModel] = useState('gpt-4o-mini')
  const [messages, setMessages] = useState([]);
  const inputValue = props.inputValue


  const handleSend = async () => {
    if (!inputValue) return;

    // Add user's message
    const userMessage = { text: inputValue, sender: 'user' };
    setMessages((prev) => [...prev, userMessage]);

    // Send message to OpenAI API
    try {
      prompt =  [
        {role: 'system', content: utils.formatKnowledge(knowledge)},
        ...messages.map((msg) => ({ role: msg.sender === 'user' ? 'user' : 'assistant', content: msg.text })),
        { role: 'user', content: inputValue },
      ];
      
      let response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          messages: prompt,
          model: "gpt-4o-mini",
          max_tokens: 300,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${constants.OPENAI_KEY}`,
          },
        }
      );

      const output = response.data.choices[0].message.content
      // Add bot's response
      const botMessage = { text: output, sender: 'bot' };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error fetching data from OpenAI API:', error);
    }

    // Clear input field
    props.setInputValue('');
  };

  const handleChange = (event) => {
    changeModel(event.target.value)
  }

  return (
    <Box sx={{padding: '1em'}}>
        <Box>
          <Select
            className="select"
            value={model}
            onChange={handleChange}
          >
            <MenuItem value={'gpt-4o-mini'}>ChatGPT 4o mini</MenuItem>
            <MenuItem value={'gpt-4o'}>ChatGPT 4o</MenuItem>
          </Select>
        </Box>
        <List sx={{ height: '70vh', overflowY: 'auto', marginBottom: '20px'}}>
          {messages.map((msg, index) => (
            <ListItem key={index}>
              <ChatBubble text={msg.text} sender={msg.sender}/>
            </ListItem>
          ))}
        </List>
        <TextField
          fullWidth
          variant="outlined"
          label="Type a message..."
          value={inputValue}
          onChange={(e) => props.setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' ? handleSend() : null}
          InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={handleSend} variant="contained" color="primary">
                    Send
                  </Button>
                </InputAdornment>
              ),
            }}
        />
    </Box>
  );
};

