import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import { ShareModule } from '../components/ShareModule';
import constants from '../constants';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DownloadAlert } from '../components/DownloadAlert';
import { LoginAlert } from '../components/LoginAlert.js';
import { Typography, Button, Box, TextField, Alert } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';
import Grid from '@mui/material/Grid2';
import utils from '../utils.js';

export function CreatePage() {
    const userInfo = useSelector((state) => (state.user))
    const isLoggedIn = userInfo.info.uid ? true : false
    const [link, setLink] = useState("")
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [checked, setChecked] = useState(false)
    const [isInstalled, setInstalled] = useState(false)
    const [success, setSuccess] = useState({})
    const [moduleId, setModuleId] = useState("")
    const [provider, setProvider] = useState("")

    function handleChange() {
        setChecked(!checked)
    }

    const loginAnonymously = async () => {
        navigate('/get-started')
    };

    const helpAddModule = async(uid, id) => {
        try {
            const data = {
                uid: uid, 
                module: id
            }
            await axios.post(`${constants.SERVER_API}/selectModule`, data)
            .then(response => {
                if (response.data) {
                    const checked = response.data.checked 
                    const modules = response.data.modules

                    chrome.runtime.sendMessage(
                        constants.CHROME_ID,
                        { user: uid, type: "save", data: {checked: checked, modules: modules}},
                        function(response) {
                            console.log('save response')
                        }
                    );
                }
            })
            .catch(error => {
                console.error(error)
                navigate('/error')
            })
        } catch (err) {
            console.log(err)
            navigate('/error')
        }
    }

    const addModule = async(id) => {
        // if user does not exist then create account for them
        if (!isLoggedIn) {
            await loginAnonymously()
        } else {
            if (id && userInfo.info.uid) {
                helpAddModule(userInfo.info.uid, id)
            }
        }
    }

    const verifyGoogleLink = (url) => {
        const pathPattern = /^\/document\/d\/[^\/]+\/(edit|view|copy)/;
        const hostname = url.hostname;
        return hostname.includes('google.com') && pathPattern.test(url.pathname);
    }

    const verifyGithubLink = (url) => {
        const hostname = url.hostname;
        return hostname.includes('github.com') && url.pathname.endsWith(".md");
    }

    const handleLinkChange = (event) => {
        let enteredLink = event.target.value 
        try {
            let parsedUrl = new URL(enteredLink);
            const hostname = parsedUrl.hostname;
            if (hostname.includes('github.com')) {
                setProvider("github")
            } else if (hostname.includes('docs.google.com')) {
                setProvider('google')
            }
            setLink(enteredLink)
        } catch {
            setLink(enteredLink)
        }
    }

    const handleSubmit = () => {
        const moduleData = {
            link: link, 
            name: name, 
            source: provider, 
            description: description,
            uid: userInfo.info.uid
        }

        const generalError = {status: false, message: "There was an error adding the module."}
        try {
            let parsedUrl = new URL(link);
            if (provider === 'google') {
                if (verifyGoogleLink(parsedUrl)) {
                    axios.post(`${constants.SERVER_API}/addGoogleModule`, moduleData)
                    .then(response => {
                        if (response.data) {
                            let data = response.data
                            if (data.success) {
                                addModule(data.id)
                                setSuccess({status: true, message: "Module added"})
                                setModuleId(data.id)
                            } else {
                                setSuccess({status: false, message: data.message})
                            }
                        } else {
                            setSuccess(generalError)
                        }
                    })
                    .catch(error => {
                        const errorMessage = error?.response?.data ? error?.response?.data : 'There was an error adding the module. Check that you are providing a properly formatted URL.'
                        console.log(error, errorMessage)
                        setSuccess({status: false, message: errorMessage.message})
                    })
                } else {
                    const docError = {status: false, message: 'Please provide a publicly viewable link to a Google Doc.'}
                    setSuccess(docError)
                }
            } else if (provider === 'github') {
                if (verifyGithubLink(parsedUrl)) {
                    axios.post(`${constants.SERVER_API}/addGithubModule`, moduleData)
                    .then(response => {
                        if (response.data) {
                            const data = response.data
                            try {
                                if (data.success) {
                                    addModule(data.id)
                                    setSuccess({status: true})
                                    setModuleId(data.id)
                                } else {
                                    setSuccess({status: false, message: data.message})
                                }
                            } catch(error) {
                                console.error(error)
                                setSuccess(generalError)
                            }
                        } else {
                            setSuccess(generalError)
                        }
                    })
                    .catch(error => {
                        console.error('here', error, generalError)
                        setSuccess(generalError)
                    })
                } else {
                    const ghError = {status: false, message: 'Please provide a link to a Github Markdown file.'}
                    setSuccess(ghError)
                }
            }
        } catch(error) {
            console.log('Error', error)
            const urlError = {status: false, message: 'There was an error adding the module. Check that you are providing a properly formatted URL.'}
            setSuccess(urlError)
        }
        // if (provider === 'google') {

        // } else if (provider === '')
    }
    //     const llmMessage = endsWithLLM()
    //     setLLMHelper(llmMessage)

    //     if (userInfo.info.uid) {
    //         if (llmMessage.length === 0) {
    //             const data = {
    //                 name: name, 
    //                 description: description,
    //                 llmLink: link,
    //                 uid: userInfo.info.uid,
    //                 isGallery: checked
    //             }
    
    //             axios.post(`${constants.SERVER_API}/addModule`, data)
    //             .then(response => {
    //                 if (response.data) {
    //                     const data = response.data
    //                     try {
    //                         if (data.success) {
    //                             setSuccess("success")
    //                             setModuleId(data.id)
    //                         } else {
    //                             setSuccess("error")
    //                             setMessage(data.message)
    //                         }
    //                     } catch(error) {
    //                         console.error(error)
    //                         setSuccess("error")
    //                         setMessage("There was an error adding the module.")
    //                     }
    //                 } else {
    //                     setSuccess("error")
    //                     setMessage("There was an error adding the module.")
    //                 }
    //             })
    //         }
    //         else {
    //                 console.log(llmMessage)
    //                 setSuccess("error")
    //                 setMessage("Check the module you are inputting again before submitting.")
    //         }
    //     } else {
    //         setSuccess("error")
    //         setMessage("User not found. Please login or continue as guest.")
    //     }
    // }


    useEffect(() => {
        utils.checkExtension(userInfo, setInstalled)
    }, [userInfo]);

    return (
        <Box>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid size={8}>
                    {
                        !isInstalled ? (
                            <DownloadAlert/>
                        ) : (
                            !isLoggedIn  ? (
                                <LoginAlert
                                    message="You will need to authenticate with Github to author a module."
                                    showGuest={false}
                                />
                            ) : null
                        )
                    }
                </Grid>
                <Grid size={8}>
                    <Typography variant="h5">
                        <strong>
                            Add a New Module
                        </strong>
                    </Typography>
                </Grid>
                <Grid size={8}>
                    <Typography variant="body1">
                        To add a new module, please provide the link to either a <strong>Github Markdown file</strong> or a link to a publicly viewable <strong>Google Doc</strong>.
                    </Typography>
                </Grid>
                <Grid size={8}>
                    <Grid container direction="column" spacing={2}>
                        <Grid xs={11}>
                            <TextField
                                label="Link to Module" 
                                variant="outlined"
                                onChange={handleLinkChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid>
                            <Typography>
                                Document Type
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid>
                                    <Button 
                                        color="secondary"
                                        variant={provider === 'github' ? 'contained' : 'outlined'}
                                        size="small"
                                        startIcon={<GitHubIcon />}
                                        onClick={() => setProvider('github')}
                                    >
                                        GitHub Markdown
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button 
                                        color="secondary"
                                        variant={provider === 'google' ? 'contained' : 'outlined'}
                                        size="small"
                                        startIcon={<GoogleIcon />}
                                        onClick={() => setProvider('google')}
                                    >
                                        Google Docs
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={11}>
                            <TextField
                                label="Module Name" 
                                variant="outlined"
                                onChange={(event) => setName(event.target.value)}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid xs={11}>
                            <TextField
                                label="Module Description" 
                                variant="outlined"
                                onChange={(event) => setDescription(event.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={11}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange}/>} label="Feature this module on the Explore page" />
                            </FormGroup>
                        </Grid>
                        <Grid>
                            <Button variant="contained" disabled={name.length === 0 || link.length === 0 || provider.length === 0 || !isInstalled || !isLoggedIn} onClick={() => handleSubmit()}>
                                Submit
                            </Button>
                        </Grid>
                        
                        <Grid>
                            {
                                'status' in success ? (
                                    <Box>
                                        <Alert sx={{margin: "1em 0"}} severity={success.status === true ? 'success' : 'error'}>
                                            {
                                                success.status === false ? (
                                                    <div>{success.message}</div>
                                                ) : (
                                                    <Grid container alignItems="center" justifyContent="space-between" sx={{width: "50vw"}}>
                                                        <Grid xs={12} md={8}>
                                                            <Typography>Module added.</Typography>
                                                        </Grid>
                                                        <Grid xs={12} md={4}>
                                                            <Grid container alignItems="center">
                                                                <Grid>
                                                                    <ShareModule id={moduleId}/>
                                                                </Grid>
                                                                <Grid>
                                                                    Share
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        </Alert>
                                    </Box>
                                ): null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
