import React, { useState } from 'react';
import { Typography, Button, Box, Card, Slider, Pagination } from '@mui/material';
import { Carousel } from '../components/Carousel'
import Grid from '@mui/material/Grid2';
import Slide1 from '../assets/Slide1.svg'
import Slide2 from '../assets/Slide2.svg'
import Slide3 from '../assets/Slide3.svg'
import Slide4 from '../assets/Slide4.svg'
import Slide5 from '../assets/Slide5.svg'


export function InstructionsPage() {
    const [idx, changeIdx] = useState(1)

    const ResponsiveSvg = ({ src, alt }) => {
        return (
          <Box
            sx={{
              width: '100%',
              height: 'auto',
            }}
          >
            <img
              src={src}
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        );
    };

    const SlideOne = () => {
        return (
            <Grid container alignItems="center" direction="column">
            <Grid size={{md: 7, xs: 10}}>
                <ResponsiveSvg src={Slide1}/>
            </Grid>
            <Grid textAlign="center" size={{md: 6, xs: 10}}>
                <Typography variant="h4">
                    <strong>
                        Welcome to Knoll!
                    </strong>
                </Typography>
                <Typography variant="h6" textAlign="left" sx={{mt: 1}}>
                    Easily create and add external information to AI chatbots in the form of <strong>knowledge modules</strong>.
                </Typography>
            </Grid>
        </Grid>
        )
    }
    const SlideTwo = () => {
        return (
            <Grid container alignItems="center" direction="column">
                <Grid size={{md: 7, xs: 10}}>
                    <ResponsiveSvg src={Slide2}/>
                </Grid>
                <Grid textAlign="center" size={{md: 6, xs: 10}}>
                    <Typography variant="h4">
                        <strong>
                            Build Your Personal Knowledge Module
                        </strong>
                    </Typography>
                    <Typography variant="h6" textAlign="left" sx={{mt: 1}}>
                        Add any knowledge you see on the Internet into your personal, private module by highlighting the text and right-clicking.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const SlideThree = () => {
        return (
            <Grid container alignItems="center" direction="column">
                <Grid size={{md: 7, xs: 10}}>
                    <ResponsiveSvg src={Slide3}/>
                </Grid>
                <Grid textAlign="center" size={{md: 6, xs: 10}}>
                    <Typography variant="h4">
                        <strong>
                            Import Existing Knowledge Modules
                        </strong>
                    </Typography>
                    <Typography variant="h6" textAlign="left" sx={{mt: 1}}>
                        Add modules that other users have published through the Explore page.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const SlideFour = () => {
        return (
            <Grid container alignItems="center" direction="column">
                <Grid size={{md: 7, xs: 10}}>
                    <ResponsiveSvg src={Slide4}/>
                </Grid>
                <Grid textAlign="center" size={{md: 6, xs: 10}}>
                    <Typography variant="h4">
                        <strong>
                            Turn Modules On and Off
                        </strong>
                    </Typography>
                    <Typography variant="h6" textAlign="left">
                        Easily toggle the modules you want activated on and off using the Knoll browser extension.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const SlideFive = () => {
        return (
            <Grid container alignItems="center" direction="column">
                <Grid size={{md: 7, xs: 10}}>
                    <ResponsiveSvg src={Slide5}/>
                </Grid>
                <Grid textAlign="center" size={{md: 6, xs: 10}}>
                    <Typography variant="h4">
                        <strong>
                            Start Chatting
                        </strong>
                    </Typography>
                    <Typography variant="h6" textAlign="left">
                        Navigate to ChatGPT or Claude and start chatting.
                        When relevant to your message, your knowledge modules will be automatically integrated.
                    </Typography>
                    <Typography variant="h6" textAlign="left" sx={{mt: 1}}>
                        If you have more questions, check the "Welcome to Knoll" module in your extension and start chatting.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const SelectedSlide = ({idx}) => {
        if (idx === 1) {
            return (
                <SlideOne/>
            )
        } else if (idx == 2) {
            return (
                <SlideTwo/>
            )
        } else if (idx == 3) {
            return (
                <SlideThree/>
            )
        } else if (idx == 4) {
            return (
                <SlideFour/>
            )
        } else if (idx == 5) {
            return (
                <SlideFive/>
            )
        }
    }

    const handleChange = (evt, value) => {
        changeIdx(value)
    };

    return (
        <Box>
            <Grid container justifyContent={'center'} alignItems="center" direction="column">
                <Grid sx={{minHeight: "80vh"}}>
                    <SelectedSlide idx={idx}/>
                </Grid>
                <Grid>
                    <Pagination count={5} page={idx} onChange={handleChange}/>
                </Grid>
            </Grid>
        </Box>
    );

}