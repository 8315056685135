/*global chrome*/
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { NavBar } from "./components/NavBar";
import { ChatPage } from "./pages/ChatPage";
import { ExamplePage } from "./pages/ExamplePage";
import { ConfirmPage } from "./pages/ConfirmPage";
import { ExplorePage } from "./pages/ExplorePage.js";
import { HomePage } from "./pages/HomePage";
import { KnowledgePage } from "./pages/KnowledgePage";
import { InstructionsPage } from "./pages/InstructionsPage";
import { AddedModulePage } from './pages/AddedModulePage';
import { AddedBundlePage } from "./pages/AddedBundlePage.js";
import { RedirectPage } from "./pages/RedirectPage";
import { ModulePage } from "./pages/ModulePage";
import { DonatePage } from './pages/DonatePage';
import { InstallPage } from "./pages/InstallPage";
import { CreatePage } from "./pages/CreatePage.js";
import { GuestPage } from "./pages/GuestPage.js";
import { UserPage } from './pages/UserPage';
import { LogoutPage } from './pages/LogoutPage';
import { LoginPage } from './pages/LoginPage';
import { NewModulePage } from './pages/NewModulePage';
import { DownloadedPage } from "./pages/DownloadedPage.js";
import { SignUpPage } from './pages/SignUpPage.js';
import { StarterPackPage } from './pages/StarterPackPage.js';
import { NotFoundPage } from './pages/NotFoundPage.js';
import { SurveyPage } from './pages/SurveyPage.js';
import { SurveySignupPage } from "./pages/SurveySignup.js";
import { CompletePage } from "./pages/CompletePage.js";
import { ContactUsPage } from "./pages/ContactUsPage.js"
import { PreferencePage } from "./pages/PreferencePage.js";
import { ShareClippingPage } from "./pages/ShareClippingPage.js";
import { PersonalModulePage } from "./pages/PersonalModulePage.js";
import { ErrorPage } from "./pages/ErrorPage.js";
import { WIPPage } from "./pages/WIPPage.js";
import { TechnicalPreferencePage } from "./pages/TechnicalPreferencePage.js";
import { Box } from "@mui/material";
import { auth } from './firebase'
import { onAuthStateChanged } from "firebase/auth";
import { addUser, deleteUser } from './reducers/userReducer.js';
import axios from 'axios';
import constants from './constants'
import theme from "./theme";
import { Helmet } from "react-helmet";
import './App.css'

export default function App() {
  const userInfo = useSelector((state) => (state.user))
  const dispatch = useDispatch()

  onAuthStateChanged(auth, async (authUser) => {
    if (authUser) {
      const update = {
          uid: authUser.uid,
          displayName: authUser.displayName
        }
      dispatch(addUser(update))
      // const response = await axios.get(`${constants.SERVER_API}/getUser`,{
      //   params: {
      //       id: authUser.uid
      //   }
      // })
      // if (response.data) {
      //   if (response.data.isUser) {
      //     const update = {
      //       uid: authUser.uid,
      //       displayName: authUser.displayName
      //     }
      //     dispatch(addUser(update))
      //   } else {
      //     console.log('No user existed')
      //     dispatch(deleteUser())
      //   }
      // }
    } else {
      console.log('Auth observer signed out')
      dispatch(deleteUser())
    }
  });

  // useEffect(() => {
  //   console.log('here')
  //    try {
  //         chrome.runtime.sendMessage(
  //             constants.CHROME_ID,
  //             { type: "check_uid" },
  //             function(response) {
  //                 if (response && response.uid) {
                    
  //                 }
  //             }
  //         );
  //     } catch(error) {
  //       console.error(error)
  //     }
  // })

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Create and add external knowledge to LLMs"/>
                <title>Knoll</title>
                <link rel="canonical" href="https://knollapp.com" />
      </Helmet>
      <Box className="mainWrapper">
        <Router>
          <NavBar user={userInfo}/>
          <Routes>
            {/* <Route path="chat" element={<ChatPage/>}/> */}
            <Route path="install/:id" element={<InstallPage/>}/>
            <Route path="add/:id" element={<AddedModulePage/>}/>
            <Route path="add2/:id" element={<AddedBundlePage/>}/>
            <Route path="explore" element={<ExplorePage/>}/>
            <Route path="explore/:id" element={<ExamplePage/>}/>
            <Route path="" element={<HomePage/>}/>
            <Route path="knowledge" element={<KnowledgePage/>}/>
            <Route path="create" element={<CreatePage/>}/>
            <Route path="link-github" element={<ShareClippingPage/>}/>
            {/* <Route path="module/:id" element={<ModulePage/>}/> */}
            <Route path="user" element={<UserPage/>}/>
            <Route path="logout" element={<LogoutPage/>}/>
            <Route path="login" element={<LoginPage/>}/>
            <Route path="get-started" element={<GuestPage/>}/>
            <Route path="signup" element={<SignUpPage/>}/>
            <Route path="download" element={<DownloadedPage/>}/>
            <Route path="starter-page/:id" element={<StarterPackPage/>}/>
            <Route path="technical-eval" element={<TechnicalPreferencePage/>}/>
            <Route path="personal" element={<PersonalModulePage/>}/>
            {/* <Route path="add/:id" element={<NewModulePage/>}/> */}
            <Route path="survey/:id" element={<SurveyPage/>}/>
            <Route path="survey-signup" element={<SurveySignupPage/>}/>
            <Route path="survey-signup/:id" element={<SurveySignupPage/>}/>
            <Route path="preference/:id" element={<PreferencePage/>}/>
            <Route path="survey-complete" element={<CompletePage/>}/>
            <Route path="login-confirmation" element={<ConfirmPage/>}/>
            <Route path="instructions" element={<InstructionsPage/>}/>
            <Route path="donate/:id" element={<DonatePage/>}/>
            <Route path="contact" element={<ContactUsPage/>}/>
            <Route path="error" element={<ErrorPage/>}/>
            <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
}
