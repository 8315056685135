import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import utils from '../utils.js';
import { Typography, Button, Box, Paper, Alert, IconButton} from '@mui/material';
import { LoginAlert } from '../components/LoginAlert';
import { DownloadAlert } from '../components/DownloadAlert';
import { useParams } from 'react-router-dom';
import constants from "../constants";
import ShareCard from '../components/ShareCard';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux'


export function ErrorAlert(props) {
    return (
        <Box>
            {
                props.userInfo?.uid ? (
                    <Alert severity="error">
                        {props.error}
                    </Alert>
                ) : null
            }
        </Box>
    )
}


export function AddedBundlePage() {
    const userInfo = useSelector((state) => (state.user))
    const isLoggedIn = userInfo.info.uid ? true : false
    const [isLoading, setLoading] = useState(true)
    const [isSuccess, setSuccess] = useState(false)
    const [showInfo, setShow] = useState(false)
    const [module, setModule] = useState({})
    const [isInstalled, setInstalled] = useState(false)
    const [error, setError] = useState(null)

    const { id } = useParams();






    const copyContent = (text) => {
        navigator.clipboard.writeText(text);
    }

    const addModule = async() => {
        // if user does not exist then create account for them
        if (!userInfo.info.uid) {
            console.log('Create account')
        }

        if (id && module.moduleIds) {
            try {
                const data = {
                    uid: userInfo.info.uid, 
                    modules: module.moduleIds,
                    id: module.id
                }
                

                await axios.post(`${constants.SERVER_API}/selectCommunity`, data)
                .then(response => {
                    if (response.data) {
                        const checked = response.data.checked 
                        const modules = response.data.modules

                        chrome.runtime.sendMessage(
                            constants.CHROME_ID,
                            { user: userInfo.info.uid, type: "save", data: {checked: checked, modules: modules}},
                            function(response) {
                                setSuccess(true)
                            }
                        );
                    }
                })
            } catch (err) {
                console.log(err)
            }
        }
    }

    const fetchModule = async () => {
        if (id) {
            await axios.get(`${constants.SERVER_API}/getCommunity`,{
                params: {
                    id: id,
                    user: userInfo.info.uid ? userInfo.info.uid : ''
                }
            })
            .then(response => {
                if (response.data) {
                    if (response.data.success && response.data.data) {
                        setModule(response.data.data)
                        if (response.data.data?.examples && response.data.data.examples.length > 0) {
                            setShow(true)
                        }
                        setError(null)
                    } else {
                        if (response.data.error) {
                            setError(response.data.error)
                        } else {
                            setError('Module was not found. Please try again later.')
                        }
                    }
                    setLoading(false)
                }
            })
        }
    };

    const directLLM = (provider) => {
        if (provider === 'openai') {
            window.open('https://chatgpt.com', '_blank');
        } else if (provider === 'claude') {
            window.open('https://claude.ai', '_blank');
        }
    }

    const toggleShow = () => {
        setShow(!showInfo)
    }
    
    useEffect(() => {
        utils.checkExtension(userInfo, setInstalled)
        fetchModule()
    }, [userInfo]);


    return (
        <Box>
            {
                isLoading ? (
                    <Box>
                        <ErrorAlert/>
                    </Box>
                ) : (
                <Grid container
                    direction="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                    sx={{height: "100%"}}
                >
                    {
                        !isInstalled ? (
                            <DownloadAlert/>
                        ) : (
                            !isLoggedIn  ? (
                                <LoginAlert
                                    message="You will need to sign in or continue as a guest to add the module."
                                    showGuest={true}
                                />
                            ) : null
                        )
                    }
                    {
                        error ? (
                            <ErrorAlert error={error} uid={userInfo.info}/>
                        ) : (
                            <Grid>
                                <Box sx={{marginBottom: "1em"}}>
                                    <ShareCard
                                        module={module}
                                        toggleShow={toggleShow}
                                        showSuccess={isSuccess}
                                        onInstall={addModule}
                                        isInstalled={isInstalled}
                                        isLoggedIn={isLoggedIn}
                                    />
                                </Box>
                            </Grid>
                        )
                    } 
                    {
                        showInfo ? (
                            <Box sx={{marginBottom: "1em"}}>
                                <Paper elevation={3} sx={{padding: "2em", width: "40vw"}}>

                                    
                                        {
                                            module.examples && module.examples.length > 0 ? (
                                                <Grid container direction="column">
                                                <Grid>
                                                    <Typography variant="body1">
                                                        Try asking questions like this...
                                                    </Typography>
                                                </Grid>
                                                <Grid container direction="column"spacing={2} >
                                                        {
                                                            module.examples.map(example => {
                                                                return (
                                                                    <Grid xs={5}>
                                                                        <Paper elevation={0} sx={{padding: "1em", border: "solid 1px #e2e2e2"}}>
                                                                            <Grid container alignContent="space-between" spacing={2}>
                                                                                <Grid size={10}>
                                                                                    <Typography variant="body2">
                                                                                        {example}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid size={1}>
                                                                                    <IconButton onClick={() => copyContent(example)}>
                                                                                        <ContentCopyIcon/>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                            
                                                                            
                                                                        </Paper>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    
                                                </Grid>
                                                </Grid>
                                            ) : (
                                                <Typography>
                                                    Sorry, there are no examples at this time. Check back later.
                                                </Typography>
                                            )
                                        }
                                    
                                </Paper>
                            </Box>
                        ) : null
                    }
                    {
                        isSuccess ? (
                            <Box>
                                <Paper elevation={3} sx={{padding: "2em", width: "40vw", backgroundColor: "#f2f2f2", outline: "#e2e2e2 solid 1px"}}>
                                    <Typography variant="h6">
                                            You have successfully added {module.name ? `the module, ${module.name}` : "the selected module"}!
                                    </Typography>
                                    <Typography>
                                        Start chatting with your favorite LLM below and the knowledge will be inserted when relevant.
                                    </Typography>
                                    <Grid container direction="row" spacing={2} sx={{margin: "0.5em 0"}}>
                                        <Grid sx={3}>
                                            <Button 
                                                variant="contained"
                                                size="small"
                                                onClick={() => directLLM('openai')}
                                            >
                                                ChatGPT
                                            </Button>
                                        </Grid>
                                        <Grid sx={3}>
                                            <Button 
                                                variant="contained"
                                                size="small"
                                                onClick={() => directLLM('claude')}
                                            >
                                                Claude
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        ) : null
                    }
                </Grid>
                )
            }
        </Box>
    );
}


