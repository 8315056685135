import React from 'react';
import constants from '../constants';
import { useParams } from 'react-router-dom';
import { Paper, Button, Typography } from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';
import Grid from '@mui/material/Grid2';

import theme from '../theme';

export function DownloadAlert() {
    const { id } = useParams();

    const onDownload = () => {
        localStorage.setItem('shared_module', id);
        window.open(constants.CHROME_LINK, '_blank');
    }

    return (
        <Paper
            elevation={0}
            severity="info"
            sx={{padding: "2em", borderColor: theme.palette.dark.default, border: "1px solid", backgroundColor: "white", width: "40vw"}}
        >
            <Grid container direction="column" spacing={2}>
                <Grid>
                    <Typography variant="body2">
                        You will need to install / enable the Knoll browser extension for Chrome to use this knowledge module. 
                    </Typography>
                </Grid>
                <Grid>
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<ExtensionIcon/>}
                        sx={{backgroundColor: theme.palette.dark.default}}
                        onClick={() => onDownload()}
                    >
                        Download Knoll
                    </Button>
                </Grid>

            </Grid>                
        </Paper>
    )
}