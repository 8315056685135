import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import "./footer.css"

export function Footer() {
    
    return (
        <Box className="footer">
            <Divider/>
            <Typography variant="body2">
                Knoll is a research prototype produced by the <a href="https://hci.stanford.edu/">Stanford Human-Computer Interaction Group</a>.
            </Typography>
        </Box>
    )
}

