import React from 'react'; 
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import "./selector.css"
export function PromptSelector(props) {
    return (
        <Box>
            {
                props.prompts.map((prompt, idx) => {
                    return (
                        <Box 
                            sx={{ boxShadow: 2}}
                            className={props.selected === idx ? "active" : "ripple"}
                            onClick={() => props.select(idx)}
                        >
                            {prompt}
                        </Box>
                    )
                })
            }
        </Box>
    )
}