import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
    useNavigate
} from "react-router-dom";

export function CompletePage() {
    return (
        <Box>
            <Grid sx={{height: "40vh"}} container justifyContent={'center'} alignItems={'center'} direction="column">
                <Grid size={5}>
                    <Typography variant="h4">
                        <strong>Thanks for your response!</strong>
                    </Typography>
                    <Typography>
                        Your survey response has been recorded.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );

}