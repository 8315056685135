import React, { useEffect, useState } from 'react';
import constants from '../constants';
import Selector from '../components/Selector';
import { Module } from '../components/Module';
import { Box, Paper, Typography, Button, Pagination, InputBase, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SearchIcon from '@mui/icons-material/Search';
import { DownloadAlert } from '../components/DownloadAlert';
import { useSelector } from 'react-redux'
import utils from '../utils.js';


export function ExplorePage() {
    const pageLimit = 8
    const userInfo = useSelector((state) => (state.user))
    const navigate = useNavigate()
    const [modules, setModule] = useState([])
    const [filteredModules, setFilteredModules] = useState([])
    const [displayModules, setDisplay] = useState([])
    const [pages, setPages] = useState(1)
    const [userModules, setUserModules] = useState([])
    const [communities, setCommunities] = useState([])
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isInstalled, setInstalled] = useState(false)
    const [page, setPage] = React.useState(1);

    

    const handleClick = (link) => {
        window.open(link, '_blank');
    };

    const fetchModules= async () => {
        try {
            await axios.get(`${constants.SERVER_API}/exploreModules`, 
                { 
                    params: { user: userInfo.info.uid }
                }
            )
            .then(response => {
                if (response.data) {
                    setModule(response.data)
                    setFilteredModules(response.data)
                    setDisplay(response.data.slice(0, pageLimit))
                    setPages(Math.ceil(response.data.length / pageLimit))
                }
            })
        } catch (err) {
            setError(err.message);
        }
    };

    const handleChange = (evt, value) => {
        const start = (value - 1) * pageLimit
        const end = start + pageLimit
        setPage(value)
        setDisplay(filteredModules.slice(start, end))
    };

    const fetchUserModules= async () => {
        try {
            await axios.get(`${constants.SERVER_API}/userModule_v2`, 
                { 
                    params: { user: userInfo.info.uid }
                }
            )
            .then(response => {
                if (response.data) {
                    const userModuleIds = {}
                    response.data.modules.forEach(module => {
                        if (module?.id) {
                            userModuleIds[module.id] = true
                        }
                    })
                    setUserModules(userModuleIds)
                }
            })
        } catch (err) {
            setError(err.message);
        }
    };

    const fetchCommunities = async() => {
        try {
            await axios.get(`${constants.SERVER_API}/communities`)
            .then(response => {
                if (response.data) {
                    setCommunities(response.data)
                }
                setLoading(false)
            })
        } catch (err) {
            setError(err.message);
        }
    }

    const filterItems = (event) => {
        const searchTerm = event.target.value
        const filteredModules = modules.filter(module =>
            module.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            module.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setPage(1)
        setFilteredModules(filteredModules)
        setDisplay(filteredModules.slice(0, pageLimit))
        setPages(Math.ceil(filteredModules.length / pageLimit))
    }

    const addModule = async(evt) => {
        const id = evt.target?.id 
        if (id) {
            try {
                const data = {
                    uid: userInfo.info.uid, 
                    module: id
                }
                await axios.post(`${constants.SERVER_API}/selectModule`, data)
                .then(response => {
                    if (response.data) {
                        let newUserModules = {...userModules}
                        newUserModules[id] = true
                        setUserModules(newUserModules)

                        const checked = response.data.checked 
                        const modules = response.data.modules

                        chrome.runtime.sendMessage(
                            constants.CHROME_ID,
                            { user: userInfo.info.uid, type: "save", data: {checked: checked, modules: modules}},
                            function(response) {
                                console.log('save response')
                            }
                        );
                    }
                })
            } catch (err) {
                console.log(err)
            }
        }
    }

    const handleNew = () => {
        navigate('/create')
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userInfo.info.uid) {
                    await fetchUserModules();
                }
                await fetchModules();
            } catch (err) {
                setError(err.message);
                console.error('Error fetching data:', err);
            }
        };
        fetchData();
        utils.checkExtension(userInfo, setInstalled);
    }, [userInfo]); 

    return (
        <Grid container sx={{justifyContent: "center", alignItems: "center"}} direction="column">
            <Grid size={8} sx={{minHeight: "80vh"}}>
                <Grid 
                    container 
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Grid size={11}>
                        <Typography style={{margin: "1em 0"}} variant="h5">
                            <strong>
                                Explore Modules
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid size={1} justifyContent="flex-end">
                        <Button 
                            variant="contained"
                            size="small" 
                            startIcon={<LibraryBooksIcon/>}
                            color="success" 
                            onClick={handleNew}
                        >
                            New
                        </Button>
                    </Grid>
                </Grid>
                <Grid xs={8}>
                    <Grid container>
                        <Grid size={4}>
                            <InputBase
                                sx={{ ml: 1, flex: 1}}
                                placeholder="Search Modules"
                                inputProps={{'aria-label': 'Search Modules' }}
                                onChange = {filterItems}
                            />
                        </Grid>
                        <Grid size={4}>
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{minHeight: "70vh"}} direction="column" alignItems="flex-start" justifyContent="flex-start">
                {
                    displayModules.map((module, idx) => {
                        return(
                            <Grid size={12}>
                                <Module
                                    title={module.name}
                                    description={module.description}
                                    id={module.id}
                                    addModule={addModule}
                                    link={module.gh_page}
                                    isAdded={module.id in userModules}
                                    isUser={userInfo.info?.uid}
                                    isInstalled={isInstalled}
                                />
                            </Grid>
                        )
                    })
                }                 
                </Grid>
            </Grid>
            <Grid size={8}>
                <Pagination count={pages} page={page} onChange={handleChange}/>
            </Grid>   
        </Grid>
    );

}