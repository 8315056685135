import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button, Alert} from '@mui/material';
import { useSelector} from 'react-redux'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import constants from '../constants';

function getWC(string) {
    const words = string.split(' ')
    return words.length
}

export function SurveySignupPage() {
    const userInfo = useSelector((state) => (state.user))
    const isLoggedIn = userInfo.info.uid ? true : false
    const { id } = useParams()
    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState({})
    const [uid, setUID] = useState(id)

    function onUpdateSurvey(evt) {
        const keyName = evt.target.id
        const value = evt.target.value 
        setEmail(value)
    }

    function postView() {
        if (uid && uid.length > 0) {
            axios.post(`${constants.SERVER_API}/updateUserInfo`, {uid: uid, surveyTime: Date.now()})
            .then(response => {
                console.log(response)
                console.log('View logged')
            })
            .catch(error => {
                console.error('Unable to log view')
            })
        }
    }

    function onSubmit() {
        if (email.length > 0) {
            const data = {
                uid: uid && uid.length > 0 ? uid : email, 
                email: email
            }

            axios.post(`${constants.SERVER_API}/submitSurvey`, data)
            .then(_ => {
                setSuccess({status: true, message: 'Your response has been recorded'})
            })
            .catch(_ => {
                setSuccess({status: false, message: 'Unable to record your response. Please email info@knollapp.com if you want to participate in the survey.'})
            })
        }
    }

    useEffect(()=> {
        if (!uid) {
            if (isLoggedIn) {
                setUID(userInfo.info.uid)
            } else {
                setUID('')
            }
        }
        postView()
    }, [id])

    return (
        <Box sx={{padding: 2}}>
            <Box sx={{marginBottom: "2em"}}>
                <Typography variant="h5">
                        Knoll Survey Interest 
                </Typography>
            </Box>
            <Grid container direction="column" spacing={2}>
                <Grid size={6}>
                    <Typography variant="body1">
                    The Stanford HCI team is running an evaluation of your experience using the system through a short survey and optional interview. You will receive a $10 gift card for completing the survey. 
                    <br/>
                    If you are interested, please provide your email below and we will contact you with the survey link if selected. 
                    </Typography>
                </Grid>
                <Grid size={6}>
                    <Typography>
                        <strong>
                        Please input your email.
                        </strong>
                    </Typography>
                    <TextField
                        sx={{mt: 1}}
                        label="Email"
                        id="email"
                        fullWidth
                        size="small"
                        onChange={onUpdateSurvey}
                    />
                </Grid>
                <Grid size={10}>
                    <Button
                        variant="contained"
                        onClick={onSubmit}
                        disabled={email.length > 0 ? false : true}
                    >
                        Submit
                    </Button>
                </Grid>
                {
                    success.status ? (
                        <Grid>
                            <Alert severity={success.status? 'success' : 'error'}>
                                {success.message}
                            </Alert>
                        </Grid>
                    ) : null
                }
            </Grid>
        </Box>
    )
}


{/* <Grid size={6}>
                    <Typography variant="body1">
                        <strong>
                            What types of prompts did you use with Knoll?
                        </strong>
                    </Typography>
                    <TextField
                        id="prompt-type"
                        fullWidth
                        multiline
                        required
                        error={errors['prompt-type']}
                        helperText={errors['prompt-type'] ? "Response must be at least 10 words long." : null}
                        rows={2}
                        onChange={onUpdateSurvey}
                    />
                </Grid >
                <Grid size={6}>
                    <Typography variant="body1">
                        <strong>
                            Would you have used these prompts without Knoll? Why or why not?
                        </strong>
                    </Typography>
                    <TextField
                        id="without-knoll"
                        fullWidth
                        multiline
                        required
                        error={errors['without-knoll']}
                        helperText={errors['without-knoll'] ? "Response must be at least 10 words long." : null}
                        rows={2}
                        onChange={onUpdateSurvey}
                    />
                </Grid > */}
                {/* <Grid size={6}>
                    < Typography>
                        <strong>
                            How much do you agree with the following statement?
                        </strong>
                    </Typography>
                    <Typography>
                        Using Knoll, I asked ChatGPT / Claude prompts that I would not have otherwise.
                    </Typography>
                    <RadioGroup>
                            <FormControlLabel value="1" control={<Radio size="small" />} label="Strongly disagree" />
                            <FormControlLabel value="2" control={<Radio size="small" />} label="Disagree" />
                            <FormControlLabel value="3" control={<Radio size="small" />} label="Neither agree nor disagree" />
                            <FormControlLabel value="4" control={<Radio size="small" />} label="Agree" />
                            <FormControlLabel value="5" control={<Radio size="small" />} label="Strongly agree" />
                    </RadioGroup>
                </Grid> */}
                // <Grid size={6}>
                //     <Typography>
                //         <strong>
                //             What other modules would you find useful to have for ChatGPT or Claude?
                //         </strong>
                //     </Typography>
                //     <TextField
                //         id='other-modules'
                //         fullWidth
                //         multiline
                //         required
                //         error={errors['other-modules']}
                //         helperText={errors['other-modules'] ? "Response must be at least 10 words long." : null}
                //         rows={2}
                //         onChange={onUpdateSurvey}
                //     />
                // </Grid>