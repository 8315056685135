import React from 'react';
import {
  Avatar,
  ListItem,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { MuiMarkdown } from 'mui-markdown';



export function ChatBubble(props) {
  return (
    <Box sx={{width: "100%"}}>
        {
            (props.sender === 'user') ? (
                <Grid container sx={{justifyContent:"flex-end"}}>
                    <ListItem style={{maxWidth: "40vw", backgroundColor:'#e0e0e0', borderRadius: '20px', padding: '0.5em 1em'}}>
                      <MuiMarkdown>{props.text}</MuiMarkdown>
                    </ListItem>
                      {/* </ListItem>primary={props.text} style={{maxWidth: "40vw", backgroundColor:'#e0e0e0', borderRadius: '20px', padding: '0.5em 1em'}}/> */}
                </Grid>
            ) : (
                <Grid container sx={{justifyContent:"flex-start"}}>
                    <Grid size={1}>
                      <Avatar sx={{ bgcolor: "#232323" }}>
                        C
                      </Avatar>
                    </Grid>
                    <Grid size={11}>
                      <ListItem style={{maxWidth: "80vw", padding: '0.5em 1em', fontSize: '12px'}}>
                        <MuiMarkdown>{props.text}</MuiMarkdown>
                      </ListItem>
                    </Grid>
                    {/* <ListItemText primary={props.text} style={{maxWidth: "80vw", padding: '0.5em 1em'}}/> */}
                </Grid>
            )
        }
    </Box>
  );
};

