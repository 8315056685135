import React, { useEffect, useState } from 'react';
import constants from '../constants';
import { Module } from '../components/Module';
import { useParams } from 'react-router-dom';
import { Box, Paper, Typography, Button, Pagination, InputBase, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import utils from '../utils.js';
import { DownloadAlert } from '../components/DownloadAlert';
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import './starterpack.css';


export function StarterPackPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    const userInfo = useSelector((state) => (state.user))
    const [modules, setModules] = useState({"modules": []})
    const [error, setError] = useState(null);
    const [userModules, setUserModules] = useState([])
    const [isInstalled, setInstalled] = useState(false)

    const checkExtension = () => {
        try {
            chrome.runtime.sendMessage(
                constants.CHROME_ID,
                { type: "check_exists" },
                function(response) {
                    if (response) {
                        setInstalled(true)
                        if (userInfo.info.uid) {
                            try {
                                chrome.runtime.sendMessage(
                                    constants.CHROME_ID,
                                    { user: userInfo.info.uid, type: "sign_in" },
                                    function(response) {
                                        console.log("Signed in")
                                    }
                                );
                            } catch(error) {
                                console.error(error)
                            }
                        }
                    }
                }
            );
        } catch(error) {
            console.error(error)
            setInstalled(false)
        }
}

    const getModules = async() => {
        if (id) {
            await axios.get(`${constants.SERVER_API}/getStarter`, 
                { 
                    params: {id: id}
                }
            )
            .then(response => {
                if (response.data) {
                    if (response.data.success) {
                        setModules(response.data.data)
                    }
                }
            })
            .catch(error => {
                console.error(error)
            })
        }
    }


    const addModule = async(evt) => {
        const id = evt.target?.id 
        if (id) {
            try {
                const data = {
                    uid: userInfo.info.uid, 
                    module: id
                }
                await axios.post(`${constants.SERVER_API}/selectModule`, data)
                .then(response => {
                    if (response.data) {
                        let newUserModules = {...userModules}
                        newUserModules[id] = true
                        setUserModules(newUserModules)
                    }
                })
            } catch (err) {
                console.log(err)
            }
        }
    }

   

    useEffect(() => {
        utils.checkExtension(userInfo, setInstalled);
        getModules();
    }, [userInfo]);

    return (
        <Grid container sx={{justifyContent: "center", alignItems: "center", padding: 1}} direction="column">
            <Grid item xs={12} sx={{minHeight: "80vh"}}>
                <Grid 
                    container 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    direction="column"
                >
                    <Grid item xs={12}>
                        <Box className="header">
                            <Typography variant="h5">
                                <strong>
                                    {modules.title}
                                </strong>
                            </Typography>
                            <Typography variant="h6">
                                Starter Pack
                            </Typography>
                        </Box>
                        <Typography style={{margin: "1em 0"}} variant="body1">     
                            {modules.description}
                        </Typography>  
                    </Grid>
                    <Grid item size={{xs:12}}>
                        <Box className="pack">
                            <Grid container direction="column" alignItems="center">
                                {
                                    Object.keys(modules["modules"]).map((sectionName, idx) => {
                                        return(
                                            <Grid size={12}>
                                                <Box>
                                                    <Typography variant = "h6">
                                                        {sectionName}
                                                    </Typography>
                                                    {
                                                        modules["modules"][sectionName].map((module, _) => {
                                                            return (
                                                                <Grid>
                                                                    <Module
                                                                        title={module.name}
                                                                        description={module.description}
                                                                        id={module.id}
                                                                        learnMore={true}
                                                                        link={module.gh_page}
                                                                    />
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                } 
                                <Grid>
                                    <Button fullWidth variant="contained" onClick={() => navigate('/explore')}>
                                        Explore Other Modules
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>  
        </Grid>
    );
}