import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { Box, Button, Alert, Typography } from "@mui/material";
import constants from "../constants";
import { getAuth, signInAnonymously } from "firebase/auth";
import { auth } from '../firebase';
import axios from 'axios'; 
import GitHubIcon from '@mui/icons-material/GitHub';
import { addUser } from '../reducers/userReducer.js';

export function GuestButton(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const signedIn = auth?.currentUser?.uid 
    const loginAnonymously = () => {
        signInAnonymously(auth)
        .then(function (result) {
            const user = result.user;
            const data = {
                uid: user.uid,
            }

            axios.post(`${constants.SERVER_API}/createGuest`, data)
            .then(result => {
                try {
                    chrome.runtime.sendMessage(
                        constants.CHROME_ID,
                        { user: user.uid, type: "sign_in" },
                        function(response) {
                            const update = {
                                uid: user.uid,
                                displayName: 'Guest'
                            }
                            dispatch(addUser(update))
                            setError('')
                        }
                    );
                } catch(error) {
                    console.error(error)
                    setError(error)
                }
                const moduleName = localStorage.getItem('shared_module');
                if (moduleName) {
                    localStorage.removeItem('shared_module');
                    navigate(`/add/${moduleName}`)
                } else {
                    navigate('/instructions')
                }
            })
        })
        .catch((error) => {
            console.log(error)
            setError(error)
        });
    };
    
    return (
        <Box>
            {signedIn ? (
                <Box>
                    <Button
                        sx={{borderRadius:"12px", backgroundColor: "#202124"}}
                        variant="contained"
                        onClick={() => navigate('/instructions')}
                    >
                        Continue
                    </Button>
                </Box>
            ) : (
                <Box>
                    {
                        props.variant === 'contained' ? (
                            <Button 
                                sx={{borderRadius:"12px", backgroundColor: "#202124"}}
                                variant="contained"
                                onClick={() => loginAnonymously()}
                            >
                                {props.text}
                            </Button>
                        ) : (
                            <Button 
                                sx={{borderRadius:"12px"}}
                                variant="text"
                                onClick={() => loginAnonymously()}
                            >
                                {props.text}
                            </Button>
                        )
                    }
                    {
                        error === '' ? null : (
                            <Alert severity="error" sx={{marginTop: "1em"}}>
                                There was an problem joining as a guest. Please try again later.
                            </Alert>
                        )
                    }
                </Box>
            )}
        </Box>
    )
}
